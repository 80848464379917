define('due-dashboard/controllers/settings/groups/index', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend({

    basic_groups: computed('model.accounts.[].profile', 'model.basic_account_info', 'i18n.locale', function () {
      var questions_rights = true,
          scenarios_rights = true,
          feedback_filters = [];
      return [{
        name: 'admin',
        chats_rights: 'all',
        surveys_rights: 'all',
        analysis_rights: 'read',
        verbatims_rights: 'read',
        exports_rights: 'all',
        templates_rights: 'all',
        questions_rights: questions_rights,
        scenarios_rights: scenarios_rights,
        feedback_filters: feedback_filters,
        account_count: this.get('model.basic_account_info.admin_count')
      }, {
        name: 'manager',
        chats_rights: 'all',
        surveys_rights: 'all',
        analysis_rights: 'none',
        verbatims_rights: 'none',
        exports_rights: 'all',
        templates_rights: 'all',
        questions_rights: questions_rights,
        scenarios_rights: scenarios_rights,
        feedback_filters: feedback_filters,
        account_count: this.get('model.basic_account_info.manager_count')
      }, {
        name: 'read_only',
        chats_rights: 'read',
        surveys_rights: 'read',
        analysis_rights: 'none',
        verbatims_rights: 'none',
        exports_rights: 'all',
        templates_rights: 'read',
        questions_rights: questions_rights,
        scenarios_rights: false,
        feedback_filters: feedback_filters,
        account_count: this.get('model.basic_account_info.read_only_count')
      }];
    }),

    currentStep: 1,
    filterCurrentStep: 1,
    filteredGroups: [],
    searchField: '',
    isLoading: false,

    groups: computed('model.account_custom_profiles.[]', 'basic_groups.[]', function () {
      return this.get('basic_groups').toArray().concat(this.get('model.account_custom_profiles').sortBy('updated_at').filterBy('isNew', false).reverse());
    }),

    updateScroll: function updateScroll() {
      var _this = this;

      var leftToQuery = arguments.length <= 0 || arguments[0] === undefined ? 50 : arguments[0];

      this.set('currentStep', this.get('currentStep') + 1);
      var filters = {};
      filters.page = { number: this.get('currentStep'), size: 50 };
      var account_custom_profiles = this.get('model.account_custom_profiles').toArray();
      this.get('store').query('account_custom_profile_right', filters).then(function (results) {
        var difference = results.toArray().filter(function (x) {
          return !account_custom_profiles.includes(x);
        });
        account_custom_profiles = [].concat(_toConsumableArray(account_custom_profiles), _toConsumableArray(difference));
        leftToQuery = leftToQuery - difference.length;
        _this.set('model.account_custom_profiles', account_custom_profiles);
        if (leftToQuery < 0 || results.toArray().length === 0) {
          _this.set('isLoading', false);
        } else {
          _this.updateScroll(leftToQuery);
        }
      });
    },

    filterGroups: function filterGroups() {
      var _this2 = this;

      var term = this.get('searchField');
      this.set('filterCurrentStep', this.get('filterCurrentStep') + 1);
      var filters = { filter: { name: term } };
      filters.page = { number: this.get('filterCurrentStep'), size: 50 };
      var filteredGroups = this.get('filteredGroups');
      this.get('store').query('account_custom_profile_right', filters).then(function (results) {
        filteredGroups = [].concat(_toConsumableArray(filteredGroups), _toConsumableArray(results.toArray()));
        _this2.set('filteredGroups', filteredGroups);
        _this2.set('isLoading', false);
      });
    },

    actions: {

      deleteGroup: function deleteGroup(group) {
        var _this3 = this;

        if (group.get('accounts.length') === 0) {
          this.set('currentStep', 0);
          group.destroyRecord()['catch'](function (e) {
            if (e.errors[0] && e.errors[0].status === 404) {
              group.unloadRecord();
            }
          }).then(function () {
            if (_this3.get('model.account_custom_profiles.length') === 49) {
              _this3.updateScroll();
            }
          });
        }
      },

      reloadGroups: function reloadGroups() {
        this.set('isLoading', true);
        if (this.get('searchField').length > 0) {
          _ember['default'].run.debounce(this, this.filterGroups, 200);
        } else {
          _ember['default'].run.debounce(this, this.updateScroll, 200);
        }
      },

      searchGroups: function searchGroups() {
        this.set('filterCurrentStep', 0);
        this.set('filteredGroups', []);
        this.set('isLoading', true);
        _ember['default'].run.debounce(this, this.filterGroups, 200);
      },

      resetScrollSteps: function resetScrollSteps() {
        this.set('currentStep', 1);
      }
    }
  });
});