define('due-dashboard/models/verbatim', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    text: attr('string'),
    resolved: attr('boolean'),
    profile: attr('string'),
    comment: attr('string'),
    email: attr('string'),
    source_zone: attr('string'),
    scale: attr('number'),
    tag: attr('string'),
    sorting_date: attr('date'),
    keywords: attr(),

    rating: belongsTo('rating'),
    survey: belongsTo('survey'),

    tags: hasMany('Tags'),
    company_segment_values: hasMany('CompanySegmentValues'),
    feedback_tags: hasMany('FeedbackTags')
  });
});