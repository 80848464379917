define('due-dashboard/components/language-dashboard-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    allLanguages: [],
    remainingLanguages: computed.setDiff('allLanguages', 'languageFilter'),
    filteredLanguages: [],
    remainingFilteredLanguages: computed.setDiff('filteredLanguages', 'languageFilter'),

    inputValue: '',

    init: function init() {
      var _this = this;

      var promise = this.store.query('language', { page: { number: 1, size: 30 } });
      this.set('promise', promise);
      promise.then(function (languages) {
        return _this.set('allLanguages', languages);
      });

      if (!this.get('filter.value')) {
        this.set('filter.value', this.get('filters.languages').mapBy('id'));
      }
      this._super.apply(this, arguments);
    },

    availableLanguages: computed('inputValue', 'remainingLanguages', 'remainingFilteredLanguages', function () {
      return this.get('inputValue.length') === 0 ? this.get('remainingLanguages') : this.get('remainingFilteredLanguages');
    }),

    languageFilter: computed('filter.value', 'allLanguages', {
      get: function get() {
        var languages = this.get('filter.value');
        this.set('filters.languages', this.get('allLanguages').toArray().filter(function (a) {
          return languages.includes(a.id);
        }));
        return this.get('allLanguages').toArray().filter(function (a) {
          return languages.includes(a.id);
        });
      }, set: function set(_, filter) {
        this.set('filter.value', filter.mapBy('id'));
        this.set('filters.languages', filter);
        this.get('modifiedSearch')();
        return filter;
      }
    }),

    languages_legend: computed('i18n.locale', 'current_company', function () {
      return this.get('current_company.custom_languages_legend') || this.get('i18n').t('sidebar.filters.languages.title');
    }),

    dueInputProps: computed('i18n.locale', 'current_company', function () {
      var i18n = this.get('i18n');
      return {
        'class': 'filter-label',
        label: this.get('i18n').t('sidebar.filters.languages.title'),
        placeholder: i18n.t('global.choose_languages')
      };
    }),

    _searchLanguage: function _searchLanguage(term) {
      var _this2 = this;

      this.set('inputValue', term);
      var promise = this.store.query('language', { filter: { name: term }, page: { number: 1, size: 20 } });
      this.set('promise', promise);
      promise.then(function (languages) {
        return _this2.set('filteredLanguages', languages);
      });
    },

    actions: {
      /**
       * Search languages in the list from its name
       * @param {String} term Language name to search
       */
      searchLanguages: function searchLanguages(term) {
        run.debounce(this, this._searchLanguage, term, 600);
      },

      /**
       * Add the selected language to the filter list
       * @param {Model<Language>} language Language selected from dropdown
       */
      onSelectLanguageFilter: function onSelectLanguageFilter(language) {
        this.set('inputValue', '');
        var languages = this.get('filters.languages');
        languages.push(language);
        this.set('filter.value', languages.mapBy('id'));
        this.set('filters.languages', languages);
        this.get('modifiedSearch')();
      },

      /**
       * Delete a language from the filters
       * @param {Model<Language>} language Language to delete
       */
      onDeleteLanguagesFilter: function onDeleteLanguagesFilter(language) {
        this.set('filter.value', this.get('filter.value').filter(function (v) {
          return v !== language.get('id');
        }));
        this.set('filters.languages', this.get('filters.languages').filter(function (l) {
          return l.get('id') !== language.get('id');
        }));
        this.get('modifiedSearch')();
      },

      /**
       * Delete the language fitler from dashboard filters
       */
      deleteFilter: function deleteFilter() {
        this.set('filters.languages', []);
        this.get('deleteFilter')(this.get('filter'));
      }
    }

  });
});