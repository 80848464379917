define('due-dashboard/objects/reports/data-converter/table-converter', ['exports', 'ember', 'due-dashboard/helpers/thousand-separator', 'due-dashboard/helpers/compute-duration'], function (exports, _ember, _dueDashboardHelpersThousandSeparator, _dueDashboardHelpersComputeDuration) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Object.extend({

    columnTypes: {
      index: { type: 'index', main_column: true },
      loadMore: { type: 'load-more', main_column: true }
    },

    _formatNumberTrend: function _formatNumberTrend(elem, properties, tableParams) {
      var value = _extends({}, elem.value, { noTrend: false, kpiTypeRate: false });

      var isComputable = properties && properties.kpi && !properties.kpi.includes('_time');

      if (value.number && typeof value.number === 'number' && value.total == null && isComputable) {
        value.number = _dueDashboardHelpersThousandSeparator['default'].compute(value.number);
      }

      if (value.total != null && isComputable) {
        value.number = _dueDashboardHelpersThousandSeparator['default'].compute(value.total);
      } else if (!isComputable) {
        value.number = value.total;
      }

      if (value.rate != null && value.total == null && value.number == null && isComputable) {
        value.number = _dueDashboardHelpersThousandSeparator['default'].compute(value.rate);
        value.rate = null;
      }

      if (elem && elem.format == 'duration') {
        value.number = _dueDashboardHelpersComputeDuration['default'].compute(value.number);
      }

      // Update to falsy condition ?
      if (value.number == 0) {
        value.number = properties && properties.display_zero ? 0 : '-';
      } else if (value.number == null) {
        value.number = '-';
      }

      value.noTrend = properties && properties.attrs && !properties.attrs.includes('trend');
      value.kpiTypeRate = properties && properties.hasOwnProperty('kpi') && properties.kpi.includes('rate');
      value.hideValue = tableParams.trendHideValue;
      return _extends({}, elem, { value: value });
    },

    _removeMetadata: function _removeMetadata(data) {
      return Object.keys(data).filter(function (k) {
        return !k.startsWith('_');
      }).reduce(function (obj, key) {
        obj[key] = data[key];
        return obj;
      }, {});
    }
  });
});