define('due-dashboard/components/tag-dashboard-filter', ['exports', 'ember', 'due-dashboard/helpers/tag-display-name'], function (exports, _ember, _dueDashboardHelpersTagDisplayName) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagManager: _ember['default'].inject.service(),

    /* Private props */
    taggedOptions: ['both', 'tagged', 'not_tagged'],
    allTags: [],
    searchTags: [],
    tagsFilterValue: '',
    remainingTags: computed.setDiff('allTags', 'tagFilter'),
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    triggerDropDownReset: false,
    flatLevelDisplay: false,
    disabledButtons: [],
    autoTagsRights: computed.alias('currentAccount.canUseAutoTags'),
    withSentiment: true,

    init: function init() {
      var _this = this;

      this.get('tagManager').getAll({
        dropdown_list_restrictions: JSON.stringify(['hidden', 'none']),
        cr_tag_survey_limited: true
      }).then(function (tags) {
        _this.set('allTags', tags);
        _this.set('searchTags', tags);
      });

      if (!this.get('filter.valueTag')) {
        this.set('filter.valueTag', this.get('filters.tags').mapBy('id'));
      } else {
        (function () {
          var tag_ids = _this.get('filter.valueTag');
          _this.set('filters.tags', _this.get('allTags').toArray().filter(function (a) {
            return tag_ids.includes(a.id);
          }));
        })();
      }
      if (!this.get('filter.value')) {
        this.set('filter.value', this.get('filters.tagged'));
      } else {
        this.set('filters.tagged', this.get('filter.value'));
      }

      if (!this.get('filter.tags_sentiments')) {
        if (this.get('filters.tags_sentiments')) {
          this.set('filter.tags_sentiments', this.get('filters.tags_sentiments'));
        } else {
          this.set('filter.tags_sentiments', {});
        }
      }
      if (!this.get('filters.tags_sentiments')) {
        this.set('filters.tags_sentiments', this.get('filter.tags_sentiments'));
      }
      this.set('filter.with_sentiment', this.get('withSentiment'));
      this._super.apply(this, arguments);
    },

    /* Defined due input props */
    dueInputProps: computed('taggedFilter', function () {
      var taggedFilter = this.get('taggedFilter');
      var i18n = this.get('i18n');
      return {
        'class': 'input-tag-text',
        label: i18n.t(taggedFilter === 'tagged' ? 'sidebar.filters.tagged.title' : 'sidebar.filters.tagged.not_tagged_title'),
        placeholder: i18n.t(taggedFilter === 'tagged' ? 'sidebar.filters.tagged.placeholder' : 'sidebar.filters.tagged.not_tagged_placeholder')
      };
    }),

    taggedFilter: computed('filter.value', {
      get: function get() {
        return this.get('filter.value');
      }, set: function set(_, filter) {
        this.set('filter.value', filter);
        this.set('filters.tagged', filter);
        this.set('filter.valueTag', []);
        this.set('filters.tags', []);
        this.set('tagSentimentFilter', {});
        this.notifyPropertyChange('tagFilter');
        this.get('modifiedSearch')();
        return filter;
      }
    }),
    tagSentimentFilter: computed('filter.valueTag', 'filter.tags_sentiments', {
      get: function get() {
        var tags_sentiments = this.get('filter.tags_sentiments');
        var tags = this.get('filter.valueTag');
        tags_sentiments = Object.fromEntries(Object.entries(tags_sentiments).filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];
          return tags.includes(key);
        }));
        tags.forEach(function (tag) {
          if (!(tag in tags_sentiments)) {
            tags_sentiments[tag] = [];
          }
        });
        this.set('filters.tags_sentiments', tags_sentiments);
        this.set('filter.tags_sentiments', tags_sentiments);
        return tags_sentiments;
      }, set: function set(_, filter) {
        this.set('filters.tags_sentiments', filter);
        this.set('filter.tags_sentiments', filter);
        return filter;
      }
    }),
    tagFilterItem: computed('tagFilter', 'allTags', 'filter.tags_sentiments', function () {
      var _this2 = this;

      var res = [];
      var tags = this.get('tagFilter').mapBy('id');
      var selectedTags = this.get('allTags').toArray().filter(function (a) {
        return tags.includes(a.id);
      });
      if (this.get('withSentiment')) {
        (function () {
          var tag_sentiment = _this2.get('tagSentimentFilter');
          selectedTags.forEach(function (t) {
            var feelings = tag_sentiment[t.id];
            if (feelings.length > 0) {
              res.push({ tag: t, feelings: feelings });
            } else {
              res.push(t);
            }
          });
        })();
      } else {
        res = selectedTags;
      }
      return res;
    }),

    tagFilter: computed('filter.valueTag', 'allTags', {
      get: function get() {
        var tags = this.get('filter.valueTag');
        if (this.get('filters.tagged') === 'not_tagged') {
          this.set('filters.excluded_tags', this.get('allTags').toArray().filter(function (a) {
            return tags.includes(a.id);
          }));
        } else if (this.get('filters.tagged') === 'tagged') {
          var tag_sentiment_filter = this.get('tagSentimentFilter');
          tags = Object.keys(tag_sentiment_filter);
          this.set('filters.tags', this.get('allTags').toArray().filter(function (a) {
            return tags.includes(a.id);
          }));
          this.set('filters.tags_sentiments', tag_sentiment_filter);
        } else {
          this.set('filters.tags', this.get('allTags').toArray().filter(function (a) {
            return tags.includes(a.id);
          }));
        }
        return this.get('allTags').toArray().filter(function (a) {
          return tags.includes(a.id);
        });
      }, set: function set(_, filter) {
        this.set('filter.valueTag', filter.mapBy('id'));
        if (this.get('filters.tagged') === 'not_tagged') {
          this.set('filters.excluded_tags', filter);
        } else {
          var tag_sentiment_filter = this.get('tagSentimentFilter');
          this.set('filters.tags_sentiments', tag_sentiment_filter);
          this.set('filters.tags', filter);
        }
        this.get('modifiedSearch')();
        return filter;
      }
    }),

    realTags: computed('searchTags', 'tagFilter', 'flatLevelDisplay', function () {
      var _this3 = this;

      var res = [];
      var tags = this.get('searchTags');
      tags !== undefined && this.get('flatLevelDisplay') === false ? tags.forEach(function (tag, _, array) {
        return _this3._computeTags(array, tag, res, _this3.get('tagFilter'));
      }) : tags.forEach(function (tag) {
        return _this3._buildTagsFlatLevelRows(tag, res, _this3.get('tagFilter'));
      });
      return res;
    }),

    _buildTagsFlatLevelRows: function _buildTagsFlatLevelRows(tag, res, selectedTags) {
      tag.set('title', _dueDashboardHelpersTagDisplayName['default'].compute(tag, this.get('currentLanguage')));
      tag.set('disabled', selectedTags.filter(function (selected) {
        return selected.get('name') == tag.get('name');
      }).length !== 0);
      res.push(tag);
    },

    _computeTags: function _computeTags(tags, tag, res, selectedTags) {
      var _this4 = this;

      var splitName = tag.get('name').split(' > ');
      var splitTitle = _dueDashboardHelpersTagDisplayName['default'].compute(tag, this.get('currentLanguage')).split('>');
      var depth = splitName.length - 1;
      tag.set('depth', splitName.length - 1);
      tag.set('displayName', splitName[tag.get('depth')]);
      tag.set('title', splitTitle[depth]);
      var subTagRegExp = "^" + tag.get('name').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";
      var subTags = tags.filter(function (item) {
        return item.get('name').match(subTagRegExp);
      });
      subTags = subTags.map(function (subTag) {
        _this4._computeTags(tags, subTag, res, selectedTags);
        subTag.set('parentTag', tag);
        return subTag;
      });

      var disabled = selectedTags.filter(function (selected) {
        return selected.get('name') == tag.get('name');
      }).length !== 0;
      if (subTags.length > 0) {
        var generalTitle = this.get('i18n').t('feedback_list.head.titles.all').string;
        var generalTag = {
          title: generalTitle, name: 'general', type: 'general',
          realTag: tag, must_be_first: true, disabled: disabled && !this.get('withSentiment'),
          feeling_enabled: tag.get('feeling_enabled') || tag.get('not_assignable')
        };
        subTags.unshiftObject(generalTag);
        tag.set('options', subTags);
        tag.set('groupName', { title: tag.get('title'), name: tag.get('displayName'), type: 'tag' });
      } else if (this.get('withSentiment')) {
        if (!tag.get('feeling_enabled')) {
          var tag_sentiment_filter = this.get('tagSentimentFilter');
          tag.set('disabled', tag.id in tag_sentiment_filter);
        } else {
          tag.set('disabled', false);
        }
      } else {
        tag.set('disabled', disabled);
      }

      if (depth === 0) {
        res.push(tag);
      }
    },

    _searchTags: function _searchTags(term) {
      var _this5 = this;

      this.get('tagManager').getAll({ name: term, dropdown_list_restrictions: JSON.stringify(['hidden', 'none']) }).then(function (tags) {
        if (!_this5.get('isDestroyed')) {
          _this5.set('searchTags', tags);
          _this5.set('showLoadingLine', '');
        }
      });
    },
    _saveTagSentiment: function _saveTagSentiment(tag, feeling) {
      var tag_sentiment_filter = this.get('tagSentimentFilter');
      var tag_id = tag.id;
      if (feeling && !tag_sentiment_filter[tag_id].includes(feeling)) tag_sentiment_filter[tag_id].push(feeling);
      this.set('tagSentimentFilter', tag_sentiment_filter);
      this.notifyPropertyChange('tagSentimentFilter');
    },

    _removeTagSentiment: function _removeTagSentiment(tag, feeling) {
      var tagSentimentFilter = this.get('tagSentimentFilter');
      var tag_id = tag.id;
      if (tag_id in tagSentimentFilter) tagSentimentFilter[tag_id].removeObject(feeling);
      this.set('tagSentimentFilter', tagSentimentFilter);
      this.notifyPropertyChange('tagSentimentFilter');
    },
    actions: {

      addTag: function addTag(tag, feeling) {
        var realTag = tag.realTag || tag;
        var temptag = this.get('tagFilter');
        temptag.pushObject(realTag);
        this.set('tagFilter', temptag);
        if (this.get('withSentiment') && (realTag.get('feeling_enabled') || realTag.get('not_assignable'))) this._saveTagSentiment(realTag, feeling);
        this.notifyPropertyChange('tagFilter');
      },

      deleteTag: function deleteTag(tag, feeling) {
        var temptag = this.get('tagFilter');
        if (this.get('withSentiment')) {
          this._removeTagSentiment(tag, feeling);
          var tagSentimentFilter = this.get('tagSentimentFilter');
          if (tag.id in tagSentimentFilter && tagSentimentFilter[tag.id] == 0) {
            temptag.removeObject(tag);
            delete tagSentimentFilter[tag.id];
            this.set('tagSentimentFilter', tagSentimentFilter);
            this.set('tagFilter', temptag);
          }
        } else {
          temptag.removeObject(tag);
          this.set('tagFilter', temptag);
        }
        this.notifyPropertyChange('tagFilter');
      },

      searchTags: function searchTags(term) {
        this.set('inputValue', term);
        this.set('showLoadingLine', 'show-loading-li');
        this.set('flatLevelDisplay', term !== '');
        this._searchTags(term);
      },

      deleteFilter: function deleteFilter() {
        this.set('tagFilter', []);
        this.set('tagSentimentFilter', {});
        this.notifyPropertyChange('tagFilter');
        this.set('filters.tagged', 'both');
        this.get('deleteFilter')(this.get('filter'));
      }
    }
  });
});