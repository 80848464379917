define('due-dashboard/routes/companies/report', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({

    filters: service('filters'),

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        report: this.get('store').findRecord('report', params.report_id),
        segmentsToDisplay: this.get('store').query('segment', { filter: { display: 'verbatim' } })
      });
    },

    afterModel: function afterModel() {
      var params = this.paramsFor(this.routeName);
      this.controllerFor('companies.report').set('report_id', params.report_id);
    }
  });
});