define('due-dashboard/helpers/init-filters', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function () {
    var additionalFilters = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

    var defaultFilters = [{ type: 'comment', value: 'both' }, { type: 'status', value: 'both' }, { type: 'profile', value: [] }, { type: 'survey', value: [] }];
    additionalFilters.forEach(function (filter) {
      defaultFilters.push(filter);
    });
    return defaultFilters;
  });
});