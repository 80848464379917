define('due-dashboard/components/status-dashboard-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    init: function init() {
      if (!this.get('filter.value')) {
        this.set('filter.value', this.get('filters.resolved'));
      }
      this._super.apply(this, arguments);
    },

    statusOptions: ['both', 'resolved', 'unresolved'],

    statusFilter: computed('filter.value', {
      get: function get() {
        this.set('filters.resolved', this.get('filter.value'));
        return this.get('filter.value');
      }, set: function set(_, filter) {
        this.set('filter.value', filter);
        this.set('filters.resolved', filter);
        this.get('modifiedSearch')();
        return filter;
      }
    }),

    actions: {

      deleteFilter: function deleteFilter() {
        this.set('filters.resolved', 'both');
        this.get('deleteFilter')(this.get('filter'));
      }
    }

  });
});