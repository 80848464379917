define('due-dashboard/components/digests/parts/kpi-list-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    actions: {
      addKpi: function addKpi() {
        var translations = this.get('translations');
        var comp_id = this.get('compId');

        if (!this.get('kpiList')) {
          this.set('kpiList', []);
        }
        Object.keys(translations).forEach(function (tag) {
          if (!translations[tag].components[comp_id].kpi_list) {
            translations[tag].components[comp_id].kpi_list = [];
          }
          translations[tag].components[comp_id].kpi_list.addObject({ title: '', year_to_date_title: '' });
        });
        this.get('kpiList').addObject({ kpi: null, is_year_to_date: false });
        this.get('saveDigestDelay')();
      },

      removeKpiItem: function removeKpiItem(kpiItem) {
        this.get('kpiList').removeObject(kpiItem);
        this.get('saveDigestDelay')();
      }
    }
  });
});