define('due-dashboard/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'moment', 'due-dashboard/helpers/check-rights-on-route'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _moment, _dueDashboardHelpersCheckRightsOnRoute) {
  // import { process } from '@ngouy/easy-template';
  /* global $ */

  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    translationsFetcher: service(),
    session: service(),
    sidebar: service(),
    notifications: service(),
    tagManager: service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        dashboard_languages: this.store.findAll('language').then(function (results) {
          return results.filter(function (language) {
            return language.get('is_available_company_side') === true;
          });
        })
      });
    },

    beforeModel: function beforeModel(transition) {
      var _this = this,
          _arguments = arguments;

      return _ember['default'].RSVP.all([_moment['default'].locale(this.get('i18n.locale')), this.get('translationsFetcher').fetch(), this._loadCurrentUser(), this._loadNotifications()]).then(function () {
        if (!_dueDashboardHelpersCheckRightsOnRoute['default'].compute([_this.currentAccount, transition.targetName])) {
          _this.transitionTo('companies.index');
        } else {
          _this._super.apply(_this, _arguments);
        }
        if (_this.get('currentAccount.content')) {
          _this._updateLocale(_this.get('currentAccount.content.language.tag').toLowerCase());
          if (_this.get('currentAccount.content.timezone_in_dashboard')) {
            _moment['default'].tz.setDefault(_this.get('currentAccount.content.timezone_id'));
          }
        }
      }).then(function () {
        _this._loadTags();
      });
    },

    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this;

      // We want to avoid redirections and loading user with digest-export, since auth is handled in child route.
      if (window.location.href.match(/\/digest-export\/new/)) {
        return;
      }

      this._loadCurrentUser().then(function () {
        if (_this2.get('currentAccount.newCompany')) {
          _this2.send('newSurvey');
        }
      })['catch'](function () {
        return _this2.get('session').invalidate();
      });
      if (!this.get('currentAccount.newCompany')) {
        this._super.apply(this, arguments);
      }
    },

    _loadCurrentUser: function _loadCurrentUser() {
      this.set('currentAccountPromise', this.get('currentAccount').loadCurrentUser());
      return this.get('currentAccountPromise');
    },

    _loadNotifications: function _loadNotifications() {
      return this.get('notifications').loadNotifications();
    },

    _loadTags: function _loadTags() {
      var _this3 = this;

      return this.get('currentAccountPromise').then(function () {
        return _this3.get('tagManager').fetchTags();
      });
    },

    _updateLocale: function _updateLocale(language) {
      _moment['default'].locale(language);
      this.get('cookie').setCookie('locale', language);
      this.get('i18n').set('locale', language);
    },

    _resetLocalStorage: function _resetLocalStorage() {
      window.localStorage.removeItem('ember_simple_auth-session');
      window.location.replace('/sign-in');
    },

    sessionInvalidated: function sessionInvalidated() {
      try {
        this.store.unloadAll();
        this._resetLocalStorage();
      } catch (e) {
        this._resetLocalStorage();
      }
    },

    actions: {

      willTransition: function willTransition(transition) {
        var _this4 = this;

        //if not protected route (sign-in/sign-up etc ... )
        var protected_route = transition.targetName.split('.').contains('companies') || transition.targetName.split('.').contains('settings');
        if (!protected_route) {
          return true;
        }

        if (transition.targetName.split('.')[0] == 'companies') {
          this.set('sidebar.dashboardSection', true);
          this.set('sidebar.settingsSection', false);
        } else {
          this.set('sidebar.dashboardSection', false);
          this.set('sidebar.settingsSection', true);
        }
        if (!this.currentAccount.content) {
          (function () {
            var target = transition.intent.url || transition.intent.name;
            transition.abort();
            _this4.get('currentAccountPromise').then(function () {
              _this4._updateLocale(_this4.get('currentAccount.content.language.tag').toLowerCase());
              if (_this4.get('currentAccount.content.timezone_in_dashboard')) {
                _moment['default'].tz.setDefault(_this4.get('currentAccount.content.timezone_id'));
              }
              if (!_dueDashboardHelpersCheckRightsOnRoute['default'].compute([_this4.currentAccount, transition.targetName])) {
                _this4.transitionTo('companies.index');
              } else {
                _this4.transitionTo(target);
              }
            });
          })();
        } else {
          this._updateLocale(this.get('currentAccount.content.language.tag').toLowerCase());
          if (this.get('currentAccount.content.timezone_in_dashboard')) {
            _moment['default'].tz.setDefault(this.get('currentAccount.content.timezone_id'));
          }
          if (!_dueDashboardHelpersCheckRightsOnRoute['default'].compute([this.currentAccount, transition.targetName])) {
            this.transitionTo('companies.index');
          } else {
            this._super.apply(this, arguments);
          }
          return true;
        }
      },

      didTransition: function didTransition() {
        $('body > div.ember-view').first().animate({ scrollTop: 0 }, 0);
      },

      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },

      newSurvey: function newSurvey() {
        var _this5 = this;

        var from_activity_sector = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

        this.store.createRecord('survey', {
          from_activity_sector: from_activity_sector,
          company: this.get('currentAccount.content.company')
        }).save().then(function (survey) {
          return _this5.transitionTo('companies.surveys.edit.main', survey.id);
        });
      }

    }
  });
});