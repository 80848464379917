define('due-dashboard/components/profile-dashboard-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  exports['default'] = Component.extend({

    init: function init() {
      if (!this.get('filter.value')) {
        this.set('filter.value', this.get('filters.profiles').mapBy('name'));
      }
      this._super.apply(this, arguments);
    },

    profileOptions: A([{ name: 'detractor', icon: '/assets/images/icons/detractor.png' }, { name: 'passive', icon: '/assets/images/icons/passive.png' }, { name: 'promoter', icon: '/assets/images/icons/promoter.png' }]),

    profileFilter: computed('filter.value', {
      get: function get() {
        var _this = this;

        var profiles = this.get('profileOptions').filter(function (profile) {
          return _this.get('filter.value').includes(profile.name);
        });
        this.set('filters.profiles', profiles);
        return profiles;
      }, set: function set(_, filter) {
        this.set('filter.value', filter.mapBy('name'));
        this.set('filters.profiles', filter);
        this.get('modifiedSearch')();
        return filter;
      }
    }),

    actions: {

      deleteFilter: function deleteFilter() {
        this.set('filters.profiles', []);
        this.get('deleteFilter')(this.get('filter'));
      }
    }

  });
});