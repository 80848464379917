define('due-dashboard/services/tag-manager', ['exports', 'ember'], function (exports, _ember) {
  var _this = this;

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    currentAccount: _ember['default'].inject.service(),

    loading: false,
    fetching: false,
    tags: null,
    rootCauses: null,

    scopes: {
      'tags_rights': function tags_rights(tag) {
        return tag.get('due_exclusive');
      },
      'auto_tags_rights': function auto_tags_rights(tag) {
        return !tag.get('due_exclusive');
      }
    },

    filtered: {
      /**
       * Search string in tag name & tag traduction
       * @param {Array<Model<Tag>>} filteredTags All tags received from list
       * @param {String} value String to compare with tag name & traduction
       * @return {Array<Model<Tag>>} All tags filtered
       */
      'name': function name(_ref) {
        var filteredTags = _ref.filteredTags;
        var value = _ref.value;
        var loweredValue;
        return regeneratorRuntime.async(function name$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              loweredValue = value.toLowerCase();
              return context$1$0.abrupt('return', filteredTags.filter(function (tag) {
                if (!tag.get('name')) return false;
                var tagLowerCase = tag.get('name').toLowerCase();
                /* TO DO OPTI SEARCH TRAD */
                return tagLowerCase.includes(loweredValue) || Object.keys(tag.get('translations')).map(function (key) {
                  return tag.get('translations')[key].text;
                }).map(function (trad) {
                  return trad.toLowerCase().includes(loweredValue);
                }).includes(true);
              }));

            case 2:
            case 'end':
              return context$1$0.stop();
          }
        }, null, _this);
      },

      /**
       * Add parent tags for full tree
       * @param {Array<Model<Tag>>} filteredTags All tags received from list already filtered
       * @param {Array<Model<Tag>>} allTags All tags fetched from API
       * @return {Array<Model<Tag>>} All tags filtered with parents added
       */
      'with_parents': function with_parents(_ref2) {
        var filteredTags = _ref2.filteredTags;
        var allTags = _ref2.allTags;

        var tagsHash, _computeTagsHash;

        return regeneratorRuntime.async(function with_parents$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              tagsHash = {};

              _computeTagsHash = function _computeTagsHash(tagNameLowerCase, tagsHash) {
                var parentTagIndex = tagNameLowerCase.lastIndexOf(' > ');
                if (parentTagIndex !== -1) _computeTagsHash(tagNameLowerCase.substr(0, parentTagIndex), tagsHash);
                tagNameLowerCase = tagNameLowerCase.substr(0, parentTagIndex);
                var filteredTag = allTags.find(function (tag) {
                  return tag.get('name').toLowerCase() === tagNameLowerCase;
                });
                if (filteredTag !== undefined) tagsHash[tagNameLowerCase] = filteredTag;
              };

              filteredTags.forEach(function (tag) {
                var tagNameLowerCase = tag.get('name').toLowerCase();
                tagsHash[tagNameLowerCase] = tag;
                _computeTagsHash(tagNameLowerCase, tagsHash);
              });

              return context$1$0.abrupt('return', Object.keys(tagsHash).map(function (key) {
                return tagsHash[key];
              }));

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, _this);
      },

      /**
       * Filter Survey limited
       * @param {Array<Model<Tag>>} filteredTags All tags received from list
       * @param {Array<String>} value List of survey limited
       * @return {Array<Model<Tag>>} All tags filtered
       */
      'survey': function survey(_ref3) {
        var filteredTags = _ref3.filteredTags;
        var value = _ref3.value;
        return regeneratorRuntime.async(function survey$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              return context$1$0.abrupt('return', filteredTags.filter(function (tag) {
                return !tag.get('survey_limited') || tag.get('surveys').find(function (survey) {
                  return survey.get('id') === value;
                });
              }));

            case 1:
            case 'end':
              return context$1$0.stop();
          }
        }, null, _this);
      },

      /**
       * Filter dropdown_list_restriction
       * @param {Array<Model<Tag>>} filteredTags All tags received from list
       * @param {Array<String>} value Account rights to get tags
       * @return {Array<Model<Tag>>} All tags filtered
       */
      'dropdown_list_restrictions': function dropdown_list_restrictions(_ref4) {
        var account = _ref4.account;
        var scopes = _ref4.scopes;
        var value = _ref4.value;
        var filteredTags = _ref4.filteredTags;
        var rights;
        return regeneratorRuntime.async(function dropdown_list_restrictions$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!(account.get('content.profile') !== 'custom')) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt('return', filteredTags);

            case 2:
              rights = account.get('rights');

              Object.keys(scopes).forEach(function (key) {
                var scope = scopes[key];
                if (value.includes(rights.get(key))) {
                  filteredTags = filteredTags.filter(function (tag) {
                    return scope(tag);
                  });
                }
              });

              return context$1$0.abrupt('return', filteredTags);

            case 5:
            case 'end':
              return context$1$0.stop();
          }
        }, null, _this);
      },

      /**
       * Filter tags with user permission survey and tag survey limited
       * @param {Model<Account>} account User account
       * @param {Boolean} value True: active filter | False: desactive filter
       * @param {Array<Model<Tag>>} filteredTags All tags filtered
       * @return {Array<Model<Tag>>} All tags filtered
       */
      'cr_tag_survey_limited': function cr_tag_survey_limited(_ref5) {
        var account = _ref5.account;
        var value = _ref5.value;
        var filteredTags = _ref5.filteredTags;
        var rights, ff, msff, filter_surveys;
        return regeneratorRuntime.async(function cr_tag_survey_limited$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!(account.get('content.profile') !== 'custom' || value === false)) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt('return', filteredTags);

            case 2:
              rights = account.get('rights');
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(rights.get('feedback_filters'));

            case 5:
              ff = context$1$0.sent;
              msff = ff.filterBy('type', 'match_surveys_feedback_filter');
              filter_surveys = msff[0] ? msff[0].get('filter_surveys').map(function (filter_survey) {
                return filter_survey.get('id');
              }) : [];
              return context$1$0.abrupt('return', filteredTags.filter(function (tag) {
                return !tag.get('survey_limited') || tag.get('surveys').find(function (survey) {
                  return filter_surveys.includes(survey.get('id'));
                });
              }));

            case 9:
            case 'end':
              return context$1$0.stop();
          }
        }, null, _this);
      }
    },

    _getValueByType: function _getValueByType(type) {
      var types = {
        'tag': function tag(self) {
          return self.get('tags');
        },
        'root_cause': function root_cause(self) {
          return self.get('rootCauses');
        },
        'action_taken': function action_taken(self) {
          return self.get('actionsTaken');
        },
        'all': function all(self) {
          return self.get('allTags');
        }
      };
      return type ? types[type](this) : [];
    },

    /**
     * Apply specific filters on tags
     * @param {Array<Model<Tag>>} tags All tags available
     * @param {Object} filters Filter to apply
     * @return Array<Model<Tag>>} tags All tags filtered
     */
    _applyFilters: function _applyFilters(tags, filters, type) {
      var filtered, account, scopes, key, fct;
      return regeneratorRuntime.async(function _applyFilters$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            filtered = this.get('filtered');
            account = this.get('currentAccount');
            scopes = this.get('scopes');
            context$1$0.t0 = regeneratorRuntime.keys(filters);

          case 4:
            if ((context$1$0.t1 = context$1$0.t0()).done) {
              context$1$0.next = 14;
              break;
            }

            key = context$1$0.t1.value;
            fct = filtered[key];

            if (fct) {
              context$1$0.next = 9;
              break;
            }

            throw new Error('[Service:tag-manager] Filter unknown: ' + key);

          case 9:
            context$1$0.next = 11;
            return regeneratorRuntime.awrap(fct({
              filteredTags: tags,
              value: filters[key],
              scopes: scopes,
              account: account,
              allTags: this._getValueByType(type) }));

          case 11:
            tags = context$1$0.sent;
            context$1$0.next = 4;
            break;

          case 14:
            return context$1$0.abrupt('return', tags);

          case 15:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    /**
     * Get all tags available
     * @return {Array<Model<Tag>>} All tags
     */
    fetchTags: function fetchTags(type) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        if (_this2.get('tags') === null && _this2.get('currentAccount.content')) {
          _this2.get('store').findAll('tag').then(function (fetchedTags) {
            var tags = fetchedTags.toArray().filter(function (tags) {
              return tags.get('kind') === 'tag' || tags.get('kind') === null;
            });
            var rootCauses = fetchedTags.toArray().filter(function (tags) {
              return tags.get('kind') === 'root_cause';
            });
            var actionsTaken = fetchedTags.toArray().filter(function (tags) {
              return tags.get('kind') === 'action_taken';
            });
            _this2.set('tags', tags);
            _this2.set('rootCauses', rootCauses);
            _this2.set('actionsTaken', actionsTaken);
            _this2.set('allTags', fetchedTags);
            resolve(_this2._getValueByType(type));
          });
        } else {
          resolve(_this2._getValueByType(type));
        }
      });
    },

    /**
     * Get all tag available for this company/user
     * @param {Object} filters Filters to apply on your tag request
     * Ex: filters = {
     *    name: {String} Text included in your tag
     *    survey: {String} survey id for sort by survey limited
     *    dropdown_list_restrictions: {Array<String>} Account rights on tags & auto tags
     * }
     * @return {Array<Model<Tag>>} All tags filtered
     */
    getAll: function getAll() {
      var filters = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var type = arguments.length <= 1 || arguments[1] === undefined ? 'tag' : arguments[1];
      return regeneratorRuntime.async(function getAll$(context$1$0) {
        var _this4 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('loading', true);
            return context$1$0.abrupt('return', new _ember['default'].RSVP.Promise(function callee$1$0(resolve, reject) {
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                var _this3 = this;

                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    try {
                      this.fetchTags(type).then(function (fetchedTags) {
                        resolve(_this3._applyFilters(fetchedTags, filters, type));
                      });
                    } catch (err) {
                      reject(err);
                    }
                    this.set('loading', false);

                  case 2:
                  case 'end':
                    return context$2$0.stop();
                }
              }, null, _this4);
            }));

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }

  });
});
/**
 * @fileoverview Manage Diduenjoy's tags on dashboard
 *
 * @service tag-manager
 * @author Eliott Martin <eliott@diduenjoy.com>
 */

/* Filter by rights restriction */