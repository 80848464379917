define('due-dashboard/routes/settings/users/edit', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    model: function model(params) {
      var _this = this;

      var account = this.get('currentAccount.content');
      return account.get('company').then(function (company) {
        return _ember['default'].RSVP.hash({
          account: _this.store.find('account', params.account_id),
          existing_groups: _this.store.findAll('account_custom_profile_right'),
          languages: _this.modelFor('application').dashboard_languages,
          profile: null,
          company: company,
          show_new_profile: false,
          available_segments: _this.store.findAll('segment'),
          new_account_segments: null
        });
      });
    },

    afterModel: function afterModel(model) {
      var _this2 = this;

      if (model.id === this.currentAccount.content.id) {
        getOwner(this).lookup('controller:application').transitionToRoute('settings.account');
        return;
      }
      model.account.get('account_custom_profile_right').then(function (acc) {
        _this2.controllerFor('settings.users.edit').set('initial_custom_profile', acc && acc.get('id'));
      });
      return this._super.apply(this, arguments);
    }
  });
});