define("due-dashboard/templates/components/reports/-tag-breakdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 8
              },
              "end": {
                "line": 9,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "header-text");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["header-column ", ["get", "header.type", ["loc", [null, [6, 38], [6, 49]]], 0, 0, 0, 0], "-column"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["subexpr", "concat", [["subexpr", "concat", ["loading-skeleton/due-", ["get", "header.component", ["loc", [null, [7, 90], [7, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 58], [7, 107]]], 0, 0], "-skeleton"], [], ["loc", [null, [7, 50], [7, 120]]], 0, 0]], ["size", "small", "width", ["subexpr", "@mut", [["get", "header.width", ["loc", [null, [7, 140], [7, 152]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [7, 164], [7, 171]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 38], [7, 173]]], 0, 0]],
          locals: ["header"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 16,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "header-text");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["header-column ", ["get", "header.type", ["loc", [null, [13, 38], [13, 49]]], 0, 0, 0, 0], "-column"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["subexpr", "concat", [["subexpr", "concat", ["loading-skeleton/due-", ["get", "header.component", ["loc", [null, [14, 92], [14, 108]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 60], [14, 109]]], 0, 0], "-skeleton"], [], ["loc", [null, [14, 52], [14, 122]]], 0, 0]], ["size", "small", "width", ["subexpr", "@mut", [["get", "header.width", ["loc", [null, [14, 142], [14, 154]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [14, 166], [14, 173]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 40], [14, 175]]], 0, 0]],
          locals: ["header"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "right-side");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createMorphAt(element4, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["left-side ", ["subexpr", "if", [["get", "isLoading", ["loc", [null, [4, 33], [4, 42]]], 0, 0, 0, 0], "isLoading"], [], ["loc", [null, [4, 28], [4, 56]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "emptyHeader.left", ["loc", [null, [5, 16], [5, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 8], [9, 17]]]], ["block", "each", [["get", "emptyHeader.right", ["loc", [null, [12, 16], [12, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 8], [16, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 8
              },
              "end": {
                "line": 24,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "header-text");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["header-column ", ["get", "header.type", ["loc", [null, [21, 38], [21, 49]]], 0, 0, 0, 0], "-column"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "header.headerLabel", ["loc", [null, [22, 38], [22, 60]]], 0, 0, 0, 0]],
          locals: ["header"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 8
              },
              "end": {
                "line": 31,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "header-text");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["header-column ", ["get", "header.type", ["loc", [null, [28, 38], [28, 49]]], 0, 0, 0, 0], "-column"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "header.headerLabel", ["loc", [null, [29, 38], [29, 60]]], 0, 0, 0, 0]],
          locals: ["header"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 33,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "left-side");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "right-side");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "headers.left", ["loc", [null, [20, 16], [20, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 8], [24, 17]]]], ["block", "each", [["get", "headers.right", ["loc", [null, [27, 16], [27, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [27, 8], [31, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 6
              },
              "end": {
                "line": 39,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "reports/array/-tag-line", [], ["isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [38, 44], [38, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "depth", 0, "emptyState", ["subexpr", "@mut", [["get", "isEmpty", ["loc", [null, [38, 73], [38, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [38, 87], [38, 92]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [38, 8], [38, 94]]], 0, 0]],
          locals: ["_", "index"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 4
            },
            "end": {
              "line": 40,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "increment-for", [], ["times", 3], 0, null, ["loc", [null, [37, 6], [39, 24]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 6
              },
              "end": {
                "line": 43,
                "column": 6
              }
            },
            "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "reports/array/-tag-line", [], ["line", ["subexpr", "@mut", [["get", "lineElems", ["loc", [null, [42, 39], [42, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [42, 57], [42, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "depth", 0, "currentLanguage", ["subexpr", "@mut", [["get", "currentLanguage", ["loc", [null, [42, 89], [42, 104]]], 0, 0, 0, 0]], [], [], 0, 0], "colIndex", ["subexpr", "@mut", [["get", "colIndex", ["loc", [null, [42, 114], [42, 122]]], 0, 0, 0, 0]], [], [], 0, 0], "source", "array"], ["loc", [null, [42, 8], [42, 139]]], 0, 0]],
          locals: ["lineElems", "colIndex"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 4
            },
            "end": {
              "line": 44,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "tagBreakdown", ["loc", [null, [41, 14], [41, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [41, 6], [43, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/reports/-tag-breakdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "array-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header-container");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content-container");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["get", "isEmpty", ["loc", [null, [3, 14], [3, 21]]], 0, 0, 0, 0], ["get", "isLoading", ["loc", [null, [3, 22], [3, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 10], [3, 32]]], 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [33, 11]]]], ["block", "if", [["subexpr", "or", [["get", "isEmpty", ["loc", [null, [36, 14], [36, 21]]], 0, 0, 0, 0], ["get", "isLoading", ["loc", [null, [36, 22], [36, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 10], [36, 32]]], 0, 0]], [], 2, 3, ["loc", [null, [36, 4], [44, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});