define('due-dashboard/components/feedback-chat-list', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    filters: service(),
    classNames: ['list-wrapper'],
    elementId: 'feedbacks-list-wrapper',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.get('selectedFeedback') && this.get('indexFeedback')) {
        this.get('scrollBack')();
      }
    },

    searchfieldChanged: observer('feedbackSearchText', function () {
      var filters = this.get('filters');
      filters.set('search_keyword', this.get('feedbackSearchText'));
      if (this.get('currentPage') !== 1) {
        this.set('doNotQuery', true);
        this.set('currentPage', 1);
      }
      run.debounce(this, this.launchQuery, 300);
    }),

    actions: {
      clickFeedback: function clickFeedback(feedback) {
        this.get("clickFeedback")(feedback);
      }
    }
  });
});