define('due-dashboard/components/filter/saved-search-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',

    actions: {
      newSearch: function newSearch() {
        this.get('newSearch')();
      },

      setSearch: function setSearch(search) {
        this.get('setSearch')(search);
      }
    }
  });
});