define('due-dashboard/components/due-trend', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-trend'],

    trendStyleIndicator: computed('trend', 'colorsConfig', function () {
      var trend = this.get('trend'),
          config = this.get('colorsConfig'),
          colors = {
        'green': 'positive',
        'neutral': 'neutral',
        'red': 'negative'
      };

      if (config) {
        if (trend >= config.neutral_threshold_min && trend <= config.neutral_threshold_max) {
          return 'neutral';
        } else if (config && trend > 0) {
          return colors[config.up];
        } else if (config) {
          return colors[config.down];
        }
      } else if (!trend) {
        return 'neutral';
      } else {
        return trend > 1 ? 'positive' : trend < -1 ? 'negative' : 'neutral';
      }
    }),

    compTrend: computed('trend', function () {
      var trend = this.get('trend');

      if (Math.abs(trend) > 2 && Math.abs(trend) < 10) {
        trend = Math.round(trend * 10) / 10;
      }

      return trend;
    })
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{due-mini-graph}} simple mini graph with only a line
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=vjgnVmi6pM05lhHW-0
 */