define('due-dashboard/controllers/companies/libraries/fonts/index', ['exports', 'ember', 'due-dashboard/helpers/date-format-array'], function (exports, _ember, _dueDashboardHelpersDateFormatArray) {
  var Controller = _ember['default'].Controller;
  var debounce = _ember['default'].run.debounce;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Controller.extend({
    filters: service(),
    isLoading: false,
    deleteModalOpen: false,
    deleteErrorModalOpen: false,
    deletePromise: null,

    availablePaginations: computed(function () {
      return [{ key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }];
    }),

    selectedPagination: { key: 10, text: '10' },

    paginationMeta: computed('model.meta', function () {
      var model = this.get('model');

      return {
        page_count: model ? model.get('meta').page_count : 0,
        current_page: model ? model.get('meta').current_page : 0
      };
    }),

    canSeeButtons: computed(function () {
      var account = this.get('currentAccount.content');

      return account.get('zone') == 'Europe';
    }),

    computedFooterSelector: computed('computedFonts', function () {
      var fonts = this.get('computedFonts');

      return fonts && fonts.length > 8 ? '.footer-container' : '';
    }),

    computedFonts: computed('model', function () {
      var fonts = this.get('model');

      if (!fonts) {
        return;
      }

      var computedFonts = fonts.map(function (font) {
        return {
          id: font.get('id'),
          name: font.get('name'),
          format: font.get('font-content-type') ? font.get('font-content-type').split('/')[1] : '🤷‍♂️',
          fallback: font.get('fallback'),
          created_at: _dueDashboardHelpersDateFormatArray['default'].compute(font.get('created_at'))
        };
      });

      return computedFonts;
    }),

    isEmpty: computed('model', function () {
      var model = this.get('model');

      return model ? model.toArray().length === 0 : false;
    }),

    _queryFonts: function _queryFonts() {
      var _this = this;

      var pagination = this.get('selectedPagination');
      var actualPage = this.get('paginationMeta');
      this.set('isLoading', true);

      var filters = { filter: {}, page: { size: pagination.key, number: actualPage.current_page }, sort: '-created_at' };
      this.store.query('font', filters).then(function (fonts) {
        _this.set('model', fonts);
        _this.set('isLoading', false);

        set(pagination, 'current_page', fonts.get('meta').current_page);
        set(pagination, 'page_count', fonts.get('meta').page_count);
        _this.set('paginationMeta', pagination);
      });
    },

    actions: {
      openDeleteFontModal: function openDeleteFontModal(index) {
        this.set('deleteModalOpen', true);
        this.set('deletionIndex', index);
      },

      openDeleteErrorModal: function openDeleteErrorModal() {
        this.set('deleteErrorModalOpen', true);
      },

      closeErrorModal: function closeErrorModal() {
        this.set('deleteErrorModalOpen', false);
      },

      deleteFont: function deleteFont() {
        var _this2 = this;

        var fonts = this.get('model').toArray();
        var index = this.get('deletionIndex');
        var font = fonts[index];
        var promise = font.destroyRecord();

        this.set('deletePromise', promise);
        promise.then(function () {
          _this2.set('deleteModalOpen', false);
          _this2.set('deletePromise', null);
          _this2._queryFonts();
        })['catch'](function (error) {
          _this2.set('deleteModalOpen', false);
          _this2.set('deletePromise', null);

          if (error.errors[0]) {
            _this2.send('openDeleteErrorModal');
          }
        });
      },

      cancel: function cancel() {
        this.set('deleteModalOpen', false);
      },

      createFont: function createFont() {
        this.transitionToRoute('companies.libraries.fonts.new');
      },

      previousPage: function previousPage() {
        var _this3 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');

          set(meta, 'current_page', meta['current_page'] - 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this3, _this3._queryFonts, resolve, reject, 600);
          });
        }
      },

      nextPage: function nextPage() {
        var _this4 = this;

        if (!this.get('isEmpty')) {
          var meta = this.get('paginationMeta');

          set(meta, 'current_page', meta['current_page'] + 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this4, _this4._queryFonts, resolve, reject, 600);
          });
        }
      },

      changePagination: function changePagination(selectedPagination) {
        var _this5 = this;

        var meta = this.get('paginationMeta');

        if (!this.get('isEmpty')) {
          this.set('selectedPagination', selectedPagination);
          set(meta, 'current_page', 1);
          this.set('paginationMeta', meta);

          return new Promise(function (resolve, reject) {
            debounce(_this5, _this5._queryFonts, resolve, reject, 600);
          });
        }
      },

      setModel: function setModel() {
        this._queryFonts();
      }
    }
  });
});