define('due-dashboard/components/deleted-dashboard-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    init: function init() {
      if (!this.get('filter.value')) {
        this.set('filter.value', this.get('filters.deleted'));
      }
      this._super.apply(this, arguments);
    },

    deletedOptions: ['not_deleted', 'deleted', 'both'],

    deletedFilter: computed('filter.value', {
      get: function get() {
        this.set('filters.deleted', this.get('filter.value'));
        return this.get('filter.value');
      }, set: function set(_, filter) {
        this.set('filter.value', filter);
        this.set('filters.deleted', filter);
        this.get('modifiedSearch')();
        return filter;
      }
    }),

    actions: {

      deleteFilter: function deleteFilter() {
        this.set('filters.deleted', 'not_deleted');
        this.get('deleteFilter')(this.get('filter'));
      }
    }

  });
});