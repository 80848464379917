define('due-dashboard/components/graphs/timeline-table', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var service = _ember['default'].inject.service;
  var set = _ember['default'].set;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;

  var statTypes = ['feedback_rate', 'completion_rate', 'open_rate', 'click_rate', 'promoter_rate', 'passive_rate', 'detractor_rate', 'verbatim_rate', 'delivery_rate'];
  exports['default'] = _ember['default'].Component.extend({
    filters: service(),
    classNames: ['timeline-container'],

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.$('.scrolling-container').css('padding-left', this.$('.fixed-column').width());
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.get('selectedIndex');
    },

    selectedIndexChanged: observer('selectedIndex', function () {
      var index = this.get('selectedIndex'),
          header_el = this.$('table th:nth-of-type(' + (index + 1) + ')');

      if (header_el) {
        header_el[0].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      }
    }),

    getEndDateFromStepType: function getEndDateFromStepType(startDateTimestamp) {
      var step = this.get('stepType.code');
      return (0, _moment['default'])(startDateTimestamp).add(1, step + 's').format(); // step = day, but  moment() needs day(s)
    },

    dataRows: computed('data.curves.[]', 'graph.lines.@each.name', 'stepType', function () {
      var _this = this;

      var curves = this.get('data.curves') || [];
      var lines = this.get('graph.lines') ? this.get('graph.lines').toArray() : [];
      var timestamps = this.get('data.timestamps');

      return curves.map(function (curve, i) {
        var line = lines[i];
        return {
          type: line.get('type'),
          filters: line.get('filters'),
          header: line.get('name'),
          color: line.get('color'),
          unit: statTypes.indexOf(line.get('type')) !== -1 ? ' %' : '',
          values: curve.values.map(function (v, idx) {
            // TODO: Refactor me with due_graph.js
            var trueValue = Number(v),
                fixedValue = parseFloat(Number(trueValue).toFixed(1));
            return {
              value: fixedValue,
              dates: [timestamps[idx], timestamps[idx + 1] || _this.getEndDateFromStepType(timestamps[idx])]
            };
          })
        };
      });
    }),

    columnHeaders: computed('data.timestamps.[]', function () {
      var timestamps = this.get('data.timestamps') || [];
      var date_format = this.get('dateFormat');

      return timestamps.map(function (ts) {
        return date_format(new Date(ts));
      });
    }),

    segmentCanBePushed: function segmentCanBePushed(segmentOid) {
      var currentFilters = this.get('filters').get('currentFilters');
      var res = currentFilters.filter(function (filter) {
        return filter.segmentId === segmentOid;
      });
      return res.length === 0;
    },

    setProfileFilter: function setProfileFilter(row) {
      var profile = { type: 'profile', value: [] };
      var profile_indexes = ['count_detractor', 'count_passive', 'count_promoter'];
      var currentFilters = this.get('filters').get('currentFilters');

      if (profile_indexes.includes(row.type)) {
        profile.value.push(row.type.split('_')[1]); // get passive from count_passive
        this.get('filters').set('profiles', [{ name: row.type.split('_')[1] }]);
        this.set('filters.hasFilter', true);
      }
      currentFilters.splice(2, 0, profile); // inserting profile object into currentFilters at index 2
      this.get('filters').set('currentFilters', currentFilters);
      return profile;
    },

    initFiltersForTransition: function initFiltersForTransition(row) {
      var _this2 = this;

      var segments = {};
      this.get('filters').set('currentFilters', [{ type: 'comment' }, { type: 'status' }, { type: 'survey' }]);
      this.setProfileFilter(row);
      row.filters.forEach(function (filter) {
        var values = filter.get('values').map(function (v) {
          return v.replace(/,/g, '，');
        });
        set(segments, filter.get('segment.oid'), values.join(','));
        if (_this2.segmentCanBePushed(filter.get('segment.oid'))) {
          _this2.get('filters.currentFilters').addObject({ type: 'segment', segmentId: filter.get('segment.oid'), value: values });
          _this2.get('filters').set('segments', segments);
        }
        _this2.set('filters.hasFilter', true);
      });
    },

    actions: {
      transitToVerbatim: function transitToVerbatim(row) {
        var column = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        var currentFilters = this.get('filters').get('currentFilters');
        if (currentFilters) {
          this.get('filters').set('currentFilters', null);
          this.set('filters.hasFilter', false);
        }
        this.initFiltersForTransition(row);
        if (column.dates && column.dates[0] && column.dates[1]) {
          this.get('filters').set('startDate', column.dates[0]);
          this.get('filters').set('endDate', column.dates[1]);
        }
        getOwner(this).lookup('controller:application').transitionToRoute('companies.verbatim');
      }
    }
  });
});