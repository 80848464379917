define('due-dashboard/components/due-multi-select-input', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;

  /**
   * @module due-multi-select-input
   * @argument {String} label (Optional) Main label displayed on top of the input
   * @argument {String} infoText (Optional) Secondary label displayed under the input
   * @argument {String} placeholder (Optional) Placeholder displayed when list is empty
   * @argument {Function} onListChange (Optional) Parent action called when the list is updated
   * @example
   *           {{ due-multi-select-input
   *              label=(t 'component.text.label')
   *              infoText=(t 'component.text.infoText')
   *              placeholder=(t 'component.input.placeholder')
   *              onListChange=(action 'triggerUpdatedList')
   *           }}
   */

  exports['default'] = Component.extend({
    classNames: ['multi-select-input'],

    init: function init() {
      this.set('selectedValues', this.get('selectedValues') || []);
      this.set('inputValue', '');
      this._super.apply(this, arguments);
    },

    /**
     * - Event call when key is down
     */
    keyDown: function keyDown(event) {
      this._handleEvent(event);
    },

    /**
     * Handle event when key is pressed
     * @param {Object} event Jquery Event
     * @return {Boolean} True: Event trigger, False: Event not trigger
     */
    _handleEvent: function _handleEvent(event) {
      var _this = this;

      var eventActions = {
        'Enter': function Enter() {
          /* Push input value in selected vaalues array when press 'Enter' and reset Input value */
          _this._addValueToList();
        }
      };

      var fct = eventActions[event.originalEvent.code];
      if (fct) fct();
      return true;
    },

    /**
     * - Add value in list unless input is empty
     */
    _addValueToList: function _addValueToList() {
      var inputValue = this.get('inputValue');
      if (this.get('inputValue.length') > 0) {
        this.get('selectedValues').pushObject(inputValue);
        this.set('inputValue', '');
        this._sendUpdatedList();
      }
    },

    /**
     * - Send list using parent action
     */
    _sendUpdatedList: function _sendUpdatedList() {
      var updateCallback = this.get('onListChange');
      /* Send list to parent if callback's present */
      if (updateCallback) {
        updateCallback(this.get('selectedValues'));
      }
    },

    actions: {
      /**
       * - Call the function to add value in list
       *   (Called when click-outside)
       */
      onAddValue: function onAddValue() {
        this._addValueToList();
      },
      /**
       * Handle event when key is pressed
       * @param {String} valueToDelete value to delete in list
       */
      onDeleteSelectedValue: function onDeleteSelectedValue(valueToDelete) {
        this.get('selectedValues').removeObject(valueToDelete);
        this._sendUpdatedList();
      }
    }
  });
});
/**
 * @author Antoine Y <antoine@diduenjoy.com> (26/10/2022)
 * @summary {{due-multi-select-input}} Input with displayed items inside
 */