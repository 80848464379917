define("due-dashboard/templates/settings/groups/new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 6
            },
            "end": {
              "line": 8,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/settings/groups/new.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["get", "button.class", ["loc", [null, [7, 50], [7, 62]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["backToIndex"], [], ["loc", [null, [7, 16], [7, 40]]], 0, 0], ["content", "button.text", ["loc", [null, [7, 66], [7, 81]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 15,
              "column": 6
            }
          },
          "moduleName": "due-dashboard/templates/settings/groups/new.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["save-settings ", ["get", "button.class", ["loc", [null, [14, 64], [14, 76]]], 0, 0, 0, 0], " "], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["createGroup"], [], ["loc", [null, [14, 16], [14, 40]]], 0, 0], ["content", "button.text", ["loc", [null, [14, 81], [14, 96]]], 0, 0, 0, 0]],
        locals: ["button"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/settings/groups/new.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "parameter");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form-actions");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2, 1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 2, 2);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "dashboard-title", ["loc", [null, [1, 0], [1, 19]]], 0, 0, 0, 0], ["inline", "create-profile", [], ["profile", ["subexpr", "@mut", [["get", "profile", ["loc", [null, [4, 29], [4, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "company", ["subexpr", "@mut", [["get", "model.company", ["loc", [null, [4, 45], [4, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "showWarning", ["subexpr", "@mut", [["get", "showWarning", ["loc", [null, [4, 71], [4, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [4, 84]]], 0, 0], ["block", "due-button", [], ["type", "cancel", "text", ["subexpr", "t", ["sidebar.filters.cancel"], [], ["loc", [null, [6, 39], [6, 67]]], 0, 0], "customClass", "cancel"], 0, null, ["loc", [null, [6, 6], [8, 21]]]], ["block", "due-button", [], ["type", "save", "disabled", ["subexpr", "not", [["get", "profile.name.length", ["loc", [null, [10, 34], [10, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 29], [10, 54]]], 0, 0], "promise", ["subexpr", "@mut", [["get", "saveGroupPromise", ["loc", [null, [11, 28], [11, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", ["words.save"], [], ["loc", [null, [12, 25], [12, 41]]], 0, 0], "customClass", "send"], 1, null, ["loc", [null, [9, 6], [15, 21]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});