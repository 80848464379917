define('due-dashboard/components/due-dropdown/due-dropdown-multilevel', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* global $ */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var RSVP = _ember['default'].RSVP;

  /**
   * @module due-dropdown-multilevel
   * @argument {Boolean} disabled (Optional) Disabled dropdown component
   * @argument {Boolean} fullView (Option) Set Width to 100%
   * @argument {Boolean} searchEnabled (Optional) Display [due-input.js] component (without custom props)
   * @argument {String} keyName (Required) Key value for the display text
   * @argument {String} keySeparator (Optional) Key to split Prefix/Suffix display text
   * @argument {Integer} maxInlineSize (Optional) Max characters on one line
   * @argument {Array} options (Required) Array of object to display on the dropdown
   * @argument {Function} onClose (Optional) Function call when dropdown closed
   * @argument {Function} onSelect (Required) Function call when item is selected
   * @argument {Function} onInput (Required)Function call when user enter an input (available only if 'searchEnabled' is true)
   * @argument {Boolean} additionalOptionsEnabled (Optional) Active or not feeling on tag with feeling
   * @argument {Array<Object>} additionalOptions (Optional) Last level options
   * @argument {String} keyAdditionalOptions (Optional) Key for enabled last level
   * @argument {Boolean} closeOnSelect (Optional) Close automaticaly if onSelect is call
   * @argument {Integer} scrollHeight (Optional) Max scroll height
   * @argument {Boolean} showLoading (Optional) [True] Show loading spinner
   * @argument {Promise} promise (Optional) Promise on loading dropdown content
   * @argument {Promise} searchPromise (Optonal) Promise on loading search dropdown
   */
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-dropdown-multilevel'],
    attributeBindings: ['style'],

    /* ### Private props ### */
    lvlNavigation: [],
    searchText: '',
    searchFieldAtBottom: false,
    focusItem: 0,
    focused: false,
    isClose: true,
    noRecord: null,
    mouseDisabled: false,
    keyId: null,

    /* ### Style props #### */
    left: 0,
    bottom: 0,
    dropDownWidth: 0,
    updateWidth: false,
    displayOnTop: false,
    triggerWidth: 0,

    /* ### Optional props ### */
    disabled: false,
    searchEnabled: true,
    keySeparator: ' > ',
    closeOnSelect: false,
    maxInlineSize: 40,
    disableBtn: false,
    lastOptions: [],
    scrollHeight: 500,
    additionalOptionsEnabled: false,
    keyAdditionalOptions: 'additionalOption',
    showLoading: true,
    additionalSentimentOptions: false,
    disabledSentiments: {},

    init: function init() {
      var _ref, _ref2, _ref3;

      this.optionsChanged();
      var additionalOptions = [(_ref = {}, _defineProperty(_ref, this.get('keyDisplay'), this.get('i18n').t('words.positive').string), _defineProperty(_ref, 'color', '#00953B'), _defineProperty(_ref, 'icon', 'tio-thumb_up_outlined'), _defineProperty(_ref, 'optionalLevel', true), _defineProperty(_ref, 'value', 'positive'), _ref), (_ref2 = {}, _defineProperty(_ref2, this.get('keyDisplay'), this.get('i18n').t('words.neutral').string), _defineProperty(_ref2, 'color', '#F08C00'), _defineProperty(_ref2, 'icon', 'tio-thumb_left_outlined'), _defineProperty(_ref2, 'optionalLevel', true), _defineProperty(_ref2, 'value', 'neutral'), _ref2), (_ref3 = {}, _defineProperty(_ref3, this.get('keyDisplay'), this.get('i18n').t('words.negative').string), _defineProperty(_ref3, 'color', '#E03131'), _defineProperty(_ref3, 'icon', 'tio-thumb_down_outlined'), _defineProperty(_ref3, 'optionalLevel', true), _defineProperty(_ref3, 'value', 'negative'), _ref3)];

      if (this.additionalSentimentOptions) {
        var _additionalOptions$unshift, _additionalOptions$push;

        additionalOptions.unshift((_additionalOptions$unshift = {}, _defineProperty(_additionalOptions$unshift, this.get('keyDisplay'), this.get('i18n').t('words.allfeedback').string), _defineProperty(_additionalOptions$unshift, 'color', '#1B3448'), _defineProperty(_additionalOptions$unshift, 'icon', 'tio-message_add_outlined'), _defineProperty(_additionalOptions$unshift, 'optionalLevel', true), _defineProperty(_additionalOptions$unshift, 'value', 'allfeedback'), _defineProperty(_additionalOptions$unshift, 'section_line', true), _additionalOptions$unshift));
        additionalOptions.push((_additionalOptions$push = {}, _defineProperty(_additionalOptions$push, this.get('keyDisplay'), this.get('i18n').t('words.nosentiment').string), _defineProperty(_additionalOptions$push, 'color', '#1B3448'), _defineProperty(_additionalOptions$push, 'icon', 'tio-label_outlined'), _defineProperty(_additionalOptions$push, 'optionalLevel', true), _defineProperty(_additionalOptions$push, 'value', 'nosentiment'), _additionalOptions$push));
      }

      if (!this.get('additionalOptions')) this.set('additionalOptions', additionalOptions);
      this._super.apply(this, arguments);
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this.optionsChanged();
      this._super.apply(this, arguments);
    },

    optionsChanged: function optionsChanged() {
      var options = this.get('options');
      this.set('lastOptions', this.get('options'));
      if (options && options.length > 0) {
        if (!options.build) {
          this.set('options.build', true);
        }
        this.set('lvlNavigation', []);
        this.set('noRecord', false);
        if (this.get('displayDynamicOption')) {
          this.set('focusItem', -1);
        } else {
          this.set('focusItem', 0);
        }
        this.lvlNavigation.push([].concat(_toConsumableArray(options)));
      } else {
        this.set('noRecord', true);
        if (this.get('displayDynamicOption')) {
          this.set('focusItem', -1);
        }
      }
    },

    /**
     * - Event call when key is down
     */
    keyDown: function keyDown(event) {
      this._handleEvent(event);
    },

    /**
     * - Active mouse focus when it mouves
     */
    mouseMove: function mouseMove() {
      if (this.get('mouseDisabled')) this.set('mouseDisabled', false);
    },

    /**
     * - Update dropdown with
     * - Force focus on search input
     */
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.element) {
        var rightFrameWidth = $('#right-frame').width() - 2,
            searchInput = this.element.querySelector('input');
        if (this.element.querySelector('.ddm-menu')) {
          var width = Math.min(this.element.querySelector('.ddm-menu').getBoundingClientRect().width, rightFrameWidth);
          if (this.get('dropDownWidth') !== width && this.get('isOpen') === true && !this.get('fullWidth')) {
            this.set('dropDownWidth', width);
            this.toggleProperty('updateWidth');
          }
        }
        if (searchInput && this.get('focused') !== false) {
          searchInput.focus();
        }
      }
    },

    /**
     * - Select the last lvl of navigation
     * - Add optional lvl if necessary
     */
    computedOptions: computed('lvlNavigation', function () {
      var lvlNavigation = this.get('lvlNavigation');

      /* No options available yet */
      if (lvlNavigation.length === 0) return [];

      /* Get the last navigation element */
      var lastLvl = lvlNavigation[lvlNavigation.length - 1];
      /* Set focus on firstItem */
      if (this.get('displayDynamicOption')) {
        this.set('focusItem', -1);
      } else {
        var index = this._findNextPrevFocus(1, 0, lastLvl, 'disabled');
        this.set('focusItem', index);
      }
      return lastLvl;
    }),

    /**
     * Update dropdown style
     */
    dropDownStyle: computed('dropDownWidth', 'triggerClientX', 'triggerClientY', 'triggerWidth', 'updateWidth', 'isClose', function () {
      this._updateDropDownPosition();
      var width = this.get('dropDownWidth'),
          bottom = this.get('bottom'),
          left = this.get('left'),
          showArrow = this.get('showArrow');
      var dpStyle = 'margin-top:' + (showArrow ? 10 : 5) + 'px;';
      if (!showArrow) dpStyle += 'min-width:' + this.get('triggerWidth') + 'px;';
      if (width && width !== 0 || this.get('fixedWidth') && !this.get('fullWidth')) dpStyle += 'width:' + (this.get('fixedWidth') || this.get('maxWidth') || width) + 'px;';
      if (left) dpStyle += 'left: -' + left + 'px;';
      if (bottom) dpStyle += 'bottom:' + bottom + 'px;';
      return htmlSafe(dpStyle);
    }),

    maxHeightScroll: computed('scrollHeight', {
      get: function get() {
        return htmlSafe('max-height: ' + this.get('scrollHeight') + 'px;');
      }
    }),

    searchFieldClasses: computed('searchFieldAtBottom', function () {
      return 'ddm-search-input ' + (this.get('searchFieldAtBottom') ? 'border-top' : '');
    }),

    /**
     * Handle loading promise on dropdown
     */
    loadingPromise: computed('promise', function () {
      var promise = [this.get('promise')];
      return _emberData['default'].PromiseArray.create({
        promise: new RSVP.Promise(function (resolve) {
          RSVP.allSettled(promise).then(function () {
            setTimeout(function () {
              resolve();
            }, 500);
          });
        })
      });
    }),

    /**
     * Handle event when key is pressed
     * @param {Object} event Jquery Event
     * @return {Boolean} True: Event trigger, False: Event not trigger
     */
    _handleEvent: function _handleEvent(event) {
      var _this = this;

      var eventActions = {
        'ArrowUp': function ArrowUp(lastLvl) {
          _this.set('mouseDisabled', true);
          if (_this.get('displayDynamicOption') && _this.get('focusItem') === 0) {
            _this.set('focusItem', _this.get('focusItem') - 1);
          } else {
            var index = _this._findNextPrevFocus(-1, _this.get('focusItem') - 1, lastLvl, 'disabled');
            _this.set('focusItem', index === -1 ? _this.get('focusItem') : index);
            _this._scrollToPosition();
          }
        },
        'ArrowDown': function ArrowDown(lastLvl) {
          _this.set('mouseDisabled', true);
          if (_this.get('displayDynamicOption') && _this.get('focusItem') === -1) {
            _this.set('focusItem', _this.get('focusItem') + 1);
          } else {
            var index = _this._findNextPrevFocus(1, _this.get('focusItem') + 1, lastLvl, 'disabled');
            _this.set('focusItem', index === -1 ? _this.get('focusItem') : index);
            _this._scrollToPosition();
          }
        },
        'ArrowRight': function ArrowRight(lastLvl) {
          if (lastLvl[_this.get('focusItem')].parent) return;
          _this._handleClickOption(lastLvl[_this.get('focusItem')], _this.get('focusItem'));
        },
        'ArrowLeft': function ArrowLeft() {
          if (_this.lvlNavigation.length === 1) return;
          _this._handleClickOptionParent();
        },
        'Enter': function Enter(lastLvl) {
          if (_this.get('displayDynamicOption') && _this.get('focusItem') === -1) {
            _this.send('onClickDynamicOption');
          } else if (lastLvl[_this.get('focusItem')].parent) {
            _this._handleClickOptionParent();
          } else {
            _this._handleClickOption(lastLvl[_this.get('focusItem')], _this.get('focusItem'));
          }
        },
        'Escape': function Escape() {
          if (_this.get('searchText') !== "") {
            _this.set('searchText', '');
            _this.get('onSearch')('');
          } else if (_this.lvlNavigation.length === 1) {
            _this._closeModal();
          } else {
            _this._handleClickOptionParent();
          }
        }
      };

      if (this.get('isClose')) return false;
      var fct = eventActions[event.originalEvent.code];
      if (fct) fct(this.lvlNavigation[this.lvlNavigation.length - 1]);
      return true;
    },

    _scrollToPosition: function _scrollToPosition() {
      if (this.get('focusItem') !== -1) {
        this.$('.ddm-options-container > ul').animate({
          scrollTop: this.get('focusItem') * this.$('li').first().outerHeight()
        }, 10);
      }
    },

    /**
     * Close the dropdown
     */
    _closeModal: function _closeModal() {
      this.set('focused', false);
      this.set('isClose', true);
      this.set('searchText', '');
      var onSearch = this.get('onSearch');
      if (onSearch) onSearch('');
      var onClose = this.get('onClose');
      if (onClose) onClose();
      return 'dropdown_closed';
    },

    /**
     * Open modal
     */
    _openModal: function _openModal() {
      this.set('focused', true);
      this.set('isClose', false);
      return 'dropdown_opened';
    },

    /**
     * Update dropdown postion on the current page
     */
    _updateDropDownPosition: function _updateDropDownPosition() {
      if (this.element) {
        var cntSelector = this.get('containerSelector'),
            _parent = cntSelector ? _ember['default'].$('body > .ember-view').find(cntSelector)[0] : this.element.parentElement,
            pageWidth = _parent.clientWidth + _parent.getBoundingClientRect().x,
            spaceLeft = pageWidth - this.get('triggerClientX'),
            heightLeft = _parent.getBoundingClientRect().y - _parent.scrollHeight,
            dpHeight = this.element.querySelector('.ddm-menu').getBoundingClientRect().height,
            width = this.get('dropDownWidth');

        if (spaceLeft < width) {
          this.set('left', width + 10 - spaceLeft);
        }
        if (heightLeft < dpHeight) {
          var bottom = this.get('triggerHeight') + 5;
          this.set('bottom', bottom);
          this.set('searchFieldAtBottom', true);
        } else {
          this.set('bottom', 0);
        }
        if (dpHeight > _parent.scrollHeight && this.get('onTopNoSpace')) {
          this.set('bottom', 30);
        }
        this.set('displayOnTop', heightLeft < dpHeight + 20);
      }
    },

    /**
     * Go back in current navigation
     */
    _handleClickOptionParent: function _handleClickOptionParent() {
      this.lvlNavigation.pop();
      this.notifyPropertyChange('lvlNavigation');
    },

    /**
     * Update navigation or callback option selected
     * @param {Object} option Dropdown option
     */
    _handleClickOption: function _handleClickOption(option) {
      var _this2 = this;

      var optAddOpts = option.get ? option.get(this.get('keyAdditionalOptions')) : option[this.get('keyAdditionalOptions')];
      if (option.options) {
        if (!option.parent) {
          var cpOption = _extends({}, option, { parent: true });
          var childOptions = [].concat(_toConsumableArray(option.options));
          childOptions.unshift(cpOption);
          this.lvlNavigation.push(childOptions);
        }
      } else if (this.get('additionalOptionsEnabled') && optAddOpts) {
        (function () {
          var cpOption = _extends({}, option, { parent: true });
          var additionalOptions = _this2.get('additionalOptions');
          var disabledSentiments = _this2.get('disabledSentiments');
          additionalOptions.forEach(function (additionalOption) {
            additionalOption.current_tag = option;
            var tag_id = option.realTag ? option.realTag.id : option.id;
            additionalOption.disabled = disabledSentiments[tag_id] && disabledSentiments[tag_id].includes(additionalOption.value);
            return additionalOption;
          });
          var feelingOptions = [].concat(_toConsumableArray(additionalOptions));
          feelingOptions.unshift(cpOption);
          _this2.lvlNavigation.push(feelingOptions);
        })();
      } else {
        var onSelect = this.get('onSelect');
        if (option.optionalLevel) {
          var lastLvl = this.lvlNavigation[this.lvlNavigation.length - 1];
          onSelect(option.current_tag || lastLvl[0], option.value);
        } else {
          onSelect(option);
        }
        if (this.get('closeOnSelect')) {
          this._closeModal();
        }
      }
      this.notifyPropertyChange('lvlNavigation');
    },

    /**
     * Find the next element available in your list
     * @param {Integer} dir -1 or 1 [-1: Up]/[1: Down]
     * @param {Integer} currIndex Current position to start search
     * @param {Array} list Element to search
     * @param {String} keyFalseRequired Key for element to be false
     * @return {Integer} Index if found else -1
     */
    _findNextPrevFocus: function _findNextPrevFocus(dir, currIndex, list, keyFalseRequired) {
      while (dir > 0 ? currIndex < list.length : currIndex >= 0) {
        if (list[currIndex].options && !list[currIndex].parent) {
          var childIndex = this._findNextPrevFocus(1, 0, list[currIndex].options, keyFalseRequired);
          if (childIndex !== -1) return currIndex;
        } else {
          if (list[currIndex].parent || !list[currIndex][keyFalseRequired]) return currIndex;
        }
        currIndex += dir;
      }
      return -1;
    },

    actions: {
      /**
       * Trigger call when item is clicked
       * @param {Object} option Option clicked
       */
      onClickOption: function onClickOption(option) {
        if (option.parent) {
          this._handleClickOptionParent();
        } else {
          this._handleClickOption(option);
        }
      },

      /**
       * Trigger call when text in search input change
       * @param {String} value Input string value
       */
      onInput: function onInput(value) {
        this.set('searchText', value);
        if (this.get('hideSearchAtRoot')) {
          this.get('onSearch')(value, (this.lvlNavigation[1] ? this.lvlNavigation[1][0] : {}).key);
        } else {
          this.get('onSearch')(value);
        }
      },

      /**
       * Trigger call when user click outside
       */
      onClickOutside: function onClickOutside() {
        if (this.get('disabled') || !this.get('focused') || this.get('isClose')) return;
        return this._closeModal();
      },

      /**
       * Trigger call when mouse go over item
       * @param {Interget} index Item position in array list
       */
      onOverItem: function onOverItem(index) {
        if (this.get('mouseDisabled')) return;
        var lastLvl = this.lvlNavigation[this.lvlNavigation.length - 1];
        var searchNotDisabledChild = function searchNotDisabledChild(children) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = children[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var child = _step.value;

              if (child.options) {
                if (searchNotDisabledChild(child.options)) return true;
              } else {
                if (!child.disabled) return true;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator['return']) {
                _iterator['return']();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          return false;
        };
        var t = lastLvl[index].realTag || lastLvl[index];
        if (t.options) {
          if (searchNotDisabledChild(t.options)) this.set('focusItem', index);
        } else {
          if (!t.disabled) this.set('focusItem', index);
        }
      },

      onHoverDynamicChild: function onHoverDynamicChild() {
        this.set('focusItem', -1);
      },

      /**
       * Trigger call when user open/close dropdown with the button
       */
      onToggle: function onToggle(option) {
        if (this.get('disabled') || option === 'open' && this.get('focused')) return;
        return this.get('focused') ? this._closeModal() : this._openModal();
      },

      onClickDynamicOption: function onClickDynamicOption() {
        var funct = this.get('dynamicOptionOnClick');
        var callbackName = this.get('dynamicOptionProps.onClick');
        if (funct) {
          funct(callbackName);
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @author Eliott Martin <eliott@diduenjoy.com> (Edit) (09/11/2021)
 * @summary {{due-dropdown-multilevel}} multi level dropdown pannel
 * @figma https://www.figma.com/file/2rgm0uaqvk0x9Pcam4yDQb/Diduenjoy-Design-System?node-id=622%3A0
 */