define('due-dashboard/components/tags/show-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  var service = _ember['default'].inject.service;
  var sort = computed.sort;
  exports['default'] = _ember['default'].Component.extend({
    filters: service(),
    tagName: '',
    tagSort: ['displayName'],
    hasSubTags: computed.notEmpty('tag.subTags'),
    showSubTags: false,
    showTagEdit: false,
    sortedTags: sort('tag.subTags', 'tagSort'),
    editionModeClass: computed('tag.due_exclusive', function () {
      return this.get('tag.due_exclusive') === true && !this.get('dueExclusiveEdit') ? 'editionDisabled' : '';
    }),
    isTagEditable: computed('dueExclusiveEdit', function () {
      if (this.get('tag.due_exclusive') && !this.get('dueExclusiveEdit')) {
        return false;
      }
      return true;
    }),

    redirections: computed(function () {
      return [{ page: this.get('i18n').t('menu.stats'), route: 'companies.overview' }, { page: this.get('i18n').t('menu.graphs'), route: 'companies.graphs' }, { page: this.get('i18n').t('menu.questions'), route: 'companies.questions' }, { page: this.get('i18n').t('menu.clients'), route: 'companies.chats' }, { page: this.get('i18n').t('menu.exports'), route: 'companies.exports' }, { page: this.get('i18n').t('menu.verbatim'), route: 'companies.verbatim' }];
    }),

    computePadding: computed('tag.depth', function () {
      return _ember['default'].String.htmlSafe('padding-left:' + (this.get('tag.depth') * 30 + 10) + 'px');
    }),

    // render only the first 3 associated surveys of a tag in table
    firstSurveys: computed('tag.surveys', function () {
      return this.get('tag.surveys').slice(0, 3);
    }),

    // calculate the number of not displayed surveys to show a "+3" like label if any remaining surveys to be displayed
    nbrHiddenSurveys: computed('tag.surveys', function () {
      var len = this.get('tag.surveys').get('length');
      return len < 4 ? 0 : len - 3;
    }),

    createdAt: computed('tag.created_at', 'i18n.locale', function () {
      var created_at = this.get('tag.created_at');
      return created_at ? created_at.toLocaleDateString(this.get('i18n.locale'), { day: 'numeric', month: 'long', year: 'numeric' }) : '...';
    }),

    tagColor: computed('tag.color', function () {
      return _ember['default'].String.htmlSafe('color: ' + this.get('tag.color'));
    }),

    isParent: computed('tag.depth', function () {
      if (this.get('tag.depth') == 0) {
        return true;
      }
      return false;
    }),

    accountDisplayed: computed('tag.account', function () {
      if (this.get('tag.account.firstname') || this.get('tag.account.lastname')) {
        return (this.get('tag.account.firstname') || '') + ' ' + (this.get('tag.account.lastname') || '');
      }
      return this.get('tag.account.email');
    }),

    editTag: function editTag() {
      var tag = this.get('tag');
      var separator = ' > ';
      var newDisplayName = this.get('TagNameTemp');
      var splitName = tag.get('name').split(separator);
      splitName[tag.depth] = newDisplayName;
      var newName = splitName.join(separator);
      this.set('tag.name', newName);
      this.set('tag.displayName', newDisplayName);
      this.get('editAction')(this.get('tag'));
      this.toggleProperty('showTagEdit');
    },

    actions: {
      redirectWithFilter: function redirectWithFilter(redirection) {
        this.set('filters.tagged', 'tagged');
        this.set('filters.tags', [this.get('tag')]);
        this.set('filters.hasFilter', true);
        getOwner(this).lookup('controller:application').transitionToRoute(redirection.route);
      },

      toggleSubTags: function toggleSubTags() {
        this.toggleProperty('showSubTags');
      },

      showSubTagCreation: function showSubTagCreation() {
        if (this.get('isTagEditable')) {
          this.toggleProperty('showSubTagCreation');
        }
      },

      showTagEdit: function showTagEdit() {
        if (this.get('isTagEditable')) {
          this.set('TagNameTemp', this.get('tag.displayName'));
          this.toggleProperty('showTagEdit');
        }
      },

      validateTagEdit: function validateTagEdit() {
        this.editTag();
      },

      CancelTagEdit: function CancelTagEdit() {
        this.toggleProperty('showTagEdit');
      },

      checkKeyValue: function checkKeyValue(_, e) {
        if (e.keyCode == 13) {
          this.editTag();
        } else if (e.keyCode == 27) {
          this.toggleProperty('showTagEdit');
        }
      }
    }
  });
});