define('due-dashboard/components/digests/parts/schedule-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    classNames: ['digest-settings-block'],

    periods: ['previous_day', 'previous_week', 'previous_month', 'previous_quarter', 'previous_semester', 'previous_year', 'current_day', 'current_week', 'current_month', 'current_quarter', 'current_semester', 'current_year'],

    days: computed('digest.draft_send_period', function () {
      if (this.get('digest.draft_send_period') == 'week') {
        return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      }
      return ['first_of_period', 'last_of_period'];
    }),

    actions: {
      mutTimeframe: function mutTimeframe(timeframe) {
        this.set('digest.draft_timeframe', timeframe);
        this.get('saveDigestDelay')();
      },

      mutPeriod: function mutPeriod(period) {
        this.set('digest.draft_send_period', period);
        if (period == 'week') {
          this.set('digest.draft_scheduled_day', 'monday');
        } else {
          this.set('digest.draft_scheduled_day', 'first_of_period');
        }
        this.get('saveDigestDelay')();
      },

      mutScheduledDay: function mutScheduledDay(day) {
        this.set('digest.draft_scheduled_day', day);
        this.get('saveDigestDelay')();
      },

      debounceTime: function debounceTime() {
        this.get('saveDigestDelay')();
      }
    }
  });
});