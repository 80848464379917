define('due-dashboard/routes/companies/chats', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var service = _ember['default'].inject.service;
  var set = _ember['default'].set;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({

    filters: service(),
    limit: 20,

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('feedbackSearchText', '');
    },

    model: function model() {
      var filters = this.get('filters.rawCookie');
      filters = filters ? { filter: filters } : this.get('filters').get('queryFilters');
      set(filters, 'page', { page: 0, size: this.get('limit') });
      set(filters, 'sort', '-chat-updated-at');
      set(filters.filter, 'completed', 'true');
      filters.filter.status = this.controller && this.controller.get('selectedStatusFilter.status') || "ongoing";
      return {
        webSnippets: this.store.findAll('web-snippet'),
        feedbacks: this.store.query('feedback', _extends({ include: 'answers' }, filters)),
        segmentsToDisplay: this.store.query('segment', { filter: { display: 'chat' } }),
        repartition: this.store.queryRecord('chat-repartition', filters)
      };
    },

    actions: {

      willTransition: function willTransition() {
        this.get('filters').set('search_keyword', '');
        this.store.unloadAll('message');
        this.store.unloadAll('company_segment_value');
        return true;
      }

    }

  });
});