define("due-dashboard/templates/settings/groups/edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/settings/groups/edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "parameter");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form-actions");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "dashboard-title", ["loc", [null, [1, 0], [1, 19]]], 0, 0, 0, 0], ["inline", "create-profile", [], ["profile", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 29], [4, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "company", ["subexpr", "@mut", [["get", "model.company", ["loc", [null, [4, 43], [4, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "showWarning", ["subexpr", "@mut", [["get", "showWarning", ["loc", [null, [4, 69], [4, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "registerApi", ["subexpr", "action", ["registerApi"], [], ["loc", [null, [4, 93], [4, 115]]], 0, 0]], ["loc", [null, [4, 4], [4, 117]]], 0, 0], ["inline", "due-btn", [], ["action", ["subexpr", "action", ["rollback"], [], ["loc", [null, [6, 23], [6, 42]]], 0, 0], "customClass", "cancel", "textKey", "sidebar.filters.cancel", "intent", "primary", "size", "medium"], ["loc", [null, [6, 6], [6, 130]]], 0, 0], ["inline", "due-btn", [], ["promise", ["subexpr", "@mut", [["get", "saveGroupPromise", ["loc", [null, [7, 24], [7, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["saveGroup"], [], ["loc", [null, [7, 48], [7, 68]]], 0, 0], "customClass", "save-settings", "textKey", "words.save", "intent", "primary", "size", "medium"], ["loc", [null, [7, 6], [7, 151]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});