define('due-dashboard/models/survey', ['exports', 'ember-data', 'due-dashboard/config/environment', 'ember'], function (exports, _emberData, _dueDashboardConfigEnvironment, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    company: belongsTo('Company', { async: true }),
    revisions: hasMany('SurveyRevision', { async: true }),
    draft_revision: belongsTo('SurveyRevision', { async: true }),
    active_revision: belongsTo('SurveyRevision', { async: true }),
    last_used_template: belongsTo('Template', { async: true }),
    has_stashed_modifications: attr('boolean'),
    name: attr('string'),
    created_at: attr('date'),
    updated_at: attr('date'),
    status: attr('string', { defaultValue: 'live' }),
    duplicate_from: attr('string'),
    from_activity_sector: attr(),
    survey_type: attr(),
    is_favorite: attr('boolean'),
    hide_dispatch_statistics: attr('boolean'),
    is_new_survey: attr('boolean'),

    surveyURL: computed('id', function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV.SURVEY_URL + '/' + this.get('id');
    }),

    creation_status: computed('has_stashed_modifications', 'active_revision.id', function () {
      if (!this.get('active_revision.id')) {
        return 'new';
      } else if (this.get('has_stashed_modifications')) {
        return 'editing';
      } else {
        return 'ready';
      }
    }),

    status_key: computed('creation_status', function () {
      return 'surveys.creation_status.is_' + this.get('creation_status');
    }),

    is_ready: computed('creation_status', function () {
      return this.get('creation_status') === 'ready';
    }),

    'new': computed('creation_status', function () {
      return this.get('creation_status') === 'new';
    }),

    sendable: computed('creation_status', 'status', function () {
      return this.get('creation_status') !== 'new' && this.get('status') === 'live';
    })

  });
});