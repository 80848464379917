define("due-dashboard/templates/components/digests/parts/kpi-list-item-part", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 4
            },
            "end": {
              "line": 34,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/parts/kpi-list-item-part.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "toggle-with-label");
          dom.setAttribute(el1, "disabled", "");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          morphs[2] = dom.createMorphAt(element1, 5, 5);
          return morphs;
        },
        statements: [["inline", "basic-tooltip", [], ["message", ["subexpr", "concat", [["subexpr", "t", ["digest.component.year_2_date_disabled"], [], ["loc", [null, [30, 40], [30, 83]]], 0, 0], " (", ["subexpr", "t", [["subexpr", "concat", ["digest.period.", ["get", "period", ["loc", [null, [30, 117], [30, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 92], [30, 124]]], 0, 0]], [], ["loc", [null, [30, 89], [30, 125]]], 0, 0], ")."], [], ["loc", [null, [30, 32], [30, 131]]], 0, 0]], ["loc", [null, [30, 8], [30, 133]]], 0, 0], ["inline", "digests/parts/one-period-part", [], ["classNames", "first", "label", "digest.component.year_2_date"], ["loc", [null, [31, 8], [31, 97]]], 0, 0], ["inline", "digests/parts/color-picker-part", [], ["options", ["subexpr", "@mut", [["get", "comp.options", ["loc", [null, [32, 50], [32, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "year_2_date_colors"], ["loc", [null, [32, 8], [32, 91]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 8
              },
              "end": {
                "line": 40,
                "column": 8
              }
            },
            "moduleName": "due-dashboard/templates/components/digests/parts/kpi-list-item-part.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "due-input", [], ["value", ["subexpr", "@mut", [["get", "kpiColumnYearToDateTitle", ["loc", [null, [39, 30], [39, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "digest.component.year_2_date", "class", "search-field", "placeholder", ["subexpr", "t", ["digest.component.title"], [], ["loc", [null, [39, 125], [39, 153]]], 0, 0], "onChange", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [39, 163], [39, 178]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [39, 12], [39, 180]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 4
            },
            "end": {
              "line": 42,
              "column": 4
            }
          },
          "moduleName": "due-dashboard/templates/components/digests/parts/kpi-list-item-part.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "toggle-with-label");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          morphs[2] = dom.createMorphAt(element0, 5, 5);
          return morphs;
        },
        statements: [["inline", "digests/parts/one-period-part", [], ["classNames", "first", "period", ["subexpr", "@mut", [["get", "kpiItem.is_year_to_date", ["loc", [null, [36, 66], [36, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "digest.component.year_2_date", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [36, 143], [36, 158]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 8], [36, 160]]], 0, 0], ["inline", "digests/parts/color-picker-part", [], ["defaultMax", ["subexpr", "@mut", [["get", "defaultMax", ["loc", [null, [37, 54], [37, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "kpiItem", ["loc", [null, [37, 73], [37, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "year_2_date_colors", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [37, 124], [37, 139]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [37, 8], [37, 141]]], 0, 0], ["block", "if", [["get", "kpiItem.is_year_to_date", ["loc", [null, [38, 14], [38, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [38, 8], [40, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/digests/parts/kpi-list-item-part.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "kpi-item-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content-child");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content-child");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content-child");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h6");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content-child kpi-period");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "remove-kpi-btn");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [7]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [9]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [11]), 1, 1);
        return morphs;
      },
      statements: [["inline", "digests/parts/one-title-part", [], ["value", ["subexpr", "@mut", [["get", "kpiColumnTitle", ["loc", [null, [4, 12], [4, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "digest.component.column_title", "placeholder", "digest.component.title", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [7, 22], [7, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 4], [8, 6]]], 0, 0], ["inline", "digests/parts/one-kpi-part", [], ["finalKpi", ["subexpr", "@mut", [["get", "kpiItem.kpi", ["loc", [null, [12, 15], [12, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "kpis", ["subexpr", "@mut", [["get", "kpis", ["loc", [null, [13, 11], [13, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [14, 22], [14, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 4], [15, 6]]], 0, 0], ["inline", "digests/parts/color-picker-part", [], ["defaultMax", 10, "options", ["subexpr", "@mut", [["get", "kpiItem", ["loc", [null, [20, 14], [20, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "colors", "text", "digest.color_label", "saveDigestDelay", ["subexpr", "@mut", [["get", "saveDigestDelay", ["loc", [null, [23, 22], [23, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [18, 4], [24, 6]]], 0, 0], ["inline", "t", ["words.options"], [], ["loc", [null, [26, 6], [26, 27]]], 0, 0], ["block", "if", [["get", "analysingYear", ["loc", [null, [28, 10], [28, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [28, 4], [42, 11]]]], ["inline", "due-btn", [], ["action", ["subexpr", "action", ["removeKpiItem"], [], ["loc", [null, [46, 13], [46, 37]]], 0, 0], "icon", "tio-clear", "textKey", "words.delete", "intent", "tertiary", "size", "small"], ["loc", [null, [45, 4], [51, 6]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});