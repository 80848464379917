define('due-dashboard/components/segment-dashboard-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var _set = _ember['default'].set;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({

    allValues: [],
    remainingValues: computed.setDiff('allValues', 'valuesFilter'),
    filteredValues: [],
    remainingFilteredValues: computed.setDiff('filteredValues', 'valuesFilter'),
    filters: service(),
    inputValue: '',

    init: function init() {
      var _this = this;

      if (typeof this.get('filter.value') === 'string') {
        var elems = this.get('filter.value').split(':');
        this.set('filter.segmentId', elems[0]);
        this.set('filter.value', elems[1].split(','));
      }
      if (!this.get('filter.segmentId')) {
        this.set('filter.segmentId', this.get('filter.segment-id'));
      }
      this.store.find('segment', this.get('filter.segmentId')).then(function (segment) {
        return _this.set('segment', segment);
      });
      var filters = this.get('filters');
      var promise = this.store.queryRecord('segment-value', { filter: { segment_id: this.get('filter.segmentId'), contains: '', start_date: filters.get('startDate'), end_date: filters.get('endDate') } });
      this.set('promise', promise);
      promise.then(function (values) {
        return _this.set('allValues', values.get('values').toArray());
      });
      this._super.apply(this, arguments);
    },

    availableValues: computed('inputValue', 'remainingValues', 'remainingFilteredValues', function () {
      return this.get('inputValue.length') === 0 ? this.get('remainingValues') : this.get('remainingFilteredValues');
    }),

    valuesFilter: computed('filter.value', 'allSurveys', {
      get: function get() {
        var values = this.get('filter.value');
        var segments = this.get('filters.segments');
        if (!values || values === null || values.length === 0) {
          // TODO: Fix it, MORCH PO!
          delete segments[this.get('filter.segmentId')];
        } else {
          _set(segments, this.get('filter.segmentId'), this._format_values(values).join(','));
        }
        this.get('filters').set('segments', segments);
        return values ? values.toArray() : values;
      }, set: function set(_, values) {
        this.set('filter.value', values);
        var segments = this.get('filters.segments');
        if (!values || values === null || values.length === 0) {
          // TODO: Fix it, MORCH PO!
          delete segments[this.get('filter.segmentId')];
        } else {
          _set(segments, this.get('filter.segmentId'), this._format_values(values).join(','));
        }
        this.get('filters').set('segments', segments);
        this.get('modifiedSearch')();
        return values;
      }
    }),

    dueInputProps: computed('i18n.locale', 'segment', function () {
      return {
        'class': 'filter-label',
        label: this.get('segment.name'),
        placeholder: ''
      };
    }),

    _searchValues: function _searchValues(term) {
      var _this2 = this;

      this.set('inputValue', term);
      var filters = this.get('filters');
      var promise = this.store.queryRecord('segment-value', { filter: { segment_id: this.get('filter.segmentId'), contains: term, start_date: filters.get('startDate'), end_date: filters.get('endDate') } });
      this.set('promise', promise);
      promise.then(function (values) {
        return _this2.set('filteredValues', values.get('values').toArray());
      });
    },

    /**
     * Format segment values
     * Avoids that segment with value like "ABC,DEF" is sent as ABC and DEF separately
     * we replace commas in segment values with a standard fullwidth comma (U+FF0C) for displaying
     */
    _format_values: function _format_values(values) {
      return values.map(function (v) {
        return v.replace(/&/g, 'ꝸ');
      }).map(function (v) {
        return v.replace(/,/g, '，');
      });
    },

    actions: {
      /**
       * Search segments in the list from its name
       * @param {String} term Segment name to search
       */
      searchValues: function searchValues(term) {
        run.debounce(this, this._searchValues, term, 600);
      },

      /**
       * Delete the segment fitler from dashboard filters
       */
      deleteFilter: function deleteFilter() {
        var segments = this.get('filters.segments');
        delete segments[this.get('filter.segmentId')];
        this.get('filters').set('segments', segments);
        this.get('deleteFilter')(this.get('filter'));
      },

      /**
       * Add the selected segment to the filter list
       * @param {Model<Segment>} segment Segment selected from dropdown
       */
      onSelectSegmentFilter: function onSelectSegmentFilter(value) {
        this.set('inputValue', '');
        var values = this.get('valuesFilter') || [];
        values.push(value);
        this.set('valuesFilter', values);
        this.get('modifiedSearch')();
        this.notifyPropertyChange('valuesFilter');
      },

      /**
       * Delete a segment from the filters
       * @param {Model<Segment>} segment Segment to delete
       */
      onDeleteSegmentsFilter: function onDeleteSegmentsFilter(value) {
        var values = this.get('valuesFilter');
        delete values[values.indexOf(value)];
        values = values.filter(function (ele) {
          return ele.length > 0;
        });
        this.set('valuesFilter', values);
        this.get('modifiedSearch')();
      }
    }

  });
});