define('due-dashboard/components/reports/-array', ['exports', 'ember', 'due-dashboard/helpers/current-timezone'], function (exports, _ember, _dueDashboardHelpersCurrentTimezone) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var copy = _ember['default'].copy;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array'],
    classNameBindings: ['layout'],

    allColumns: ['index', 'icon', 'main-label', 'number-trend', 'breakdown', 'positive-impact', 'negative-impact', 'label-with-sub'],
    filters: service(),

    isLoading: true,
    isEmpty: false,
    nameModalIsOpened: false,
    progressModalIsOpened: false,
    formatOptions: ['csv', 'xlsx'],
    fileContentOptions: ['displayed_view', 'full_hierarchy'],

    // PLACEHOLDERS
    emptyHeader: {
      leftSideHeader: [{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }],
      rightSideHeader: [{ type: 'label-with-sub', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'text', width: 80 }]
    },

    reloadObserver: observer('reload', function () {
      this.set('content_data', []);
      this.set('pageIndex', 1);
      if (this.get('selectedViewBy') && typeof this.get('selectedViewBy') === 'string') {
        this._queryData(this.get('querySegments')[this.get('selectedViewBy').toLowerCase()]);
      } else {
        this._queryData();
      }
    }),

    emptyPage: computed('', function () {
      return {
        leftSideContent: [[{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }], [{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }], [{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }], [{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }], [{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }]],
        rightSideContent: [[{ type: 'index', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'nps-line', width: 150 }, { type: 'label-with-sub', component: 'text', width: 1600 }], [{ type: 'index', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'nps-line', width: 150 }, { type: 'label-with-sub', component: 'text', width: 1600 }], [{ type: 'index', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'nps-line', width: 150 }, { type: 'label-with-sub', component: 'text', width: 1600 }], [{ type: 'index', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'nps-line', width: 150 }, { type: 'label-with-sub', component: 'text', width: 1600 }], [{ type: 'index', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'nps-line', width: 150 }, { type: 'label-with-sub', component: 'text', width: 1600 }]]
      };
    }),

    viewByOpts: computed('content', function () {
      var _this = this;

      if (!this.get('content.component.config.view_by')) {
        return [];
      }

      var segments = this.get('content.component.config.view_by.segments'),
          querySegments = {};
      var viewByOpts = [];

      if (this.get('currentAccount.content.profile') !== 'custom') {
        viewByOpts = Object.keys(segments).map(function (s) {
          querySegments[s.toLowerCase()] = segments[s];
          return s.charAt(0).toUpperCase() + s.slice(1);
        });
        this.set('querySegments', querySegments);
      } else {
        (function () {
          var hierarchy = _this.get('currentAccount.content.account_custom_profile_right.content.reports_hierarchy').map(function (h) {
            return h.toLowerCase();
          }),
              availableSegments = Object.keys(segments).filter(function (s) {
            return hierarchy.includes(s.toLowerCase());
          });

          viewByOpts = availableSegments.map(function (s) {
            querySegments[s.toLowerCase()] = segments[s];
            return s.charAt(0).toUpperCase() + s.slice(1);
          });
        })();
      }

      this.set('querySegments', querySegments);
      return viewByOpts;
    }),

    selectedViewBy: computed('viewByOpts', function () {
      var opt = this.get('viewByOpts')[0];
      if (this.get('content.component.config.default_view_by')) {
        var defaultValue = this.get('content.component.config.default_view_by');
        opt = defaultValue.charAt(0).toUpperCase() + defaultValue.slice(1);
      }

      if (this.get('content.component.config.view_by')) {
        this._queryData(this.get('querySegments')[opt.toLowerCase()]);
      }
      return opt;
    }),

    displayViewBy: computed('viewByOpts', function () {
      return this.get('viewByOpts.length') > 1;
    }),

    init: function init() {
      this._super();
      // if (false) { this._queryVerbatims(); } commented by youba code never reached
      if (!this.get('content.component.basicProperties')) {
        this.set('content.component.basicProperties', copy(this.get('content.component.properties')));
      } else {
        this.set('content.component.properties', copy(this.get('content.component.basicProperties')));
      }
      if (!this.get('content.component.config.view_by')) {
        this._queryData();
      }
      if (!this.get('content').component.config.pagination) {
        this.set('limit', { key: this.get('content').component.config.page_size || 25, text: 'all' });
      }
    },

    _queryVerbatims: function _queryVerbatims() {
      var _this2 = this;

      this.store.query('verbatim', this.get('filters').get('queryFilters')).then(function (verbatims) {
        return _this2.set('verbatims', verbatims);
      });
    },

    _queryData: function _queryData() {
      var _this3 = this;

      var object_id = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var content = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      this.set('isLoading', true);
      this.set('isEmpty', false);
      // Get all fitlers and data from component & get global filters
      var dashboardQueryFilters = this.get('filters.queryFilters').filter,
          reportQueryFilters = this.get('reportFilters'),
          componentFilters = this.get('content.component.filters'),
          componentProperties = content || this.get('content.component.basicProperties'),
          dataQueryFilters = this.get('filters.mergeQueryFilters')(dashboardQueryFilters, reportQueryFilters, componentFilters),
          dataQuery = this.get('store').createRecord('data-query'),
          dateRange = {
        from: '',
        to: ''
      };

      // Set date_range if present in filters
      if (dataQueryFilters.start_date && dataQueryFilters.end_date) {
        dateRange.from = moment(dataQueryFilters.start_date[0][0]).format().split('T')[0];
        dateRange.to = moment(dataQueryFilters.end_date[0][0]).format().split('T')[0];
      } else {
        // Set past 30 days if not in fitlers
        var date = new Date();
        dateRange.to = moment(Date.now()).format().split('T')[0];
        date.setDate(date.getDate() - 30);
        dateRange.from = moment(date).format().split('T')[0];
      }

      // Remove date range from filters
      dataQuery.set('date_range', dateRange);
      delete dataQueryFilters.start_date;
      delete dataQueryFilters.end_date;
      dataQuery.set('filters', dataQueryFilters);
      dataQuery.set('properties', componentProperties);
      dataQuery.set('front_format', true);
      dataQuery.set('group', this.get('content.component.view_type'));
      dataQuery.set('object_ids', this.get('content.component.config.object_ids'));
      dataQuery.set('object_id', object_id || this.get('content.component.config.object_id'));
      dataQuery.set('sort', this.get('content.component.config.sort'));
      dataQuery.set('lvls', this.get('content.component.config.lvls'));
      dataQuery.set('kind', this.get('content.component.config.kind'));
      dataQuery.set('subkind', this.get('content.component.config.subkind'));
      dataQuery.set('page_number', this.get('pageIndex'));
      dataQuery.set('page_size', this.get('limit').key);
      dataQuery.set('pagination', this.get('content.component.config.pagination'));
      dataQuery.set('translations', this.get('content.component.config.translations'));
      dataQuery.set('query_segments', this.get('querySegments'));
      dataQuery.set('tz', _dueDashboardHelpersCurrentTimezone['default'].compute());

      this.set('dataQueryForExport', dataQuery);

      if (this.get('canView')) {
        dataQuery.save().then(function (response) {
          var data = _this3.get('content_data') || [];
          _this3.set('content_data', data.concat(response.get('content_data')));
          _this3.set('pageCount', response.get('page_count') || 1);
          _this3.set('isLoading', false);
          _this3.set('isEmpty', data.concat(response.get('content_data')).length === 0);
        })['catch'](function (error) {
          // Empty state when catched errors
          _this3.set('isLoading', false);
          _this3.set('isEmpty', true);
        });
      } else {
        this.set('isEmpty', true);
      }
    },

    limits: computed(function () {
      return [{ key: 10, text: '10' }, { key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }];
    }),

    limit: { key: 10, text: '10' },

    pageIndex: 1,
    pageCount: 1,

    page: computed('pages', 'pageIndex', function () {
      var pages = this.get('pages'),
          pageIndex = this.get('pageIndex');
      return pages[pageIndex - 1];
    }),

    leftArrowDisabled: computed('pageIndex', 'pageCount', function () {
      return this.get('pageIndex') === 1;
    }),

    rightArrowDisabled: computed('pageIndex', 'pageCount', function () {
      return this.get('pageIndex') === this.get('pageCount');
    }),

    nbLeftColumns: computed('page', function () {
      return this.get('page').leftSideContent[0].length;
    }),

    disabledExport: computed('isLoading', function () {
      return this.get('page').leftSideContent[0].length;
    }),

    headers: computed('content', 'selectedViewBy', function () {
      var _this4 = this;

      var component = this.get('content').component,
          leftSideHeader = [],
          rightSideHeader = [];

      if (component.view_type === 'tags' || component.view_type === 'segments') {
        var headerTxt = component.config.view_by ? this.get('selectedViewBy') : component.config.translations[this.get('currentLanguage')].headers[0];
        if (component.hasOwnProperty('properties') && typeof component['properties'] === 'object' && component['properties'][0]) {
          if (component['properties'][0].type == 'label-with-sub') {
            component['properties'][0].headerLabel = headerTxt;
          } else {
            component.properties.splice(0, 0, { type: 'label-with-sub', headerLabel: headerTxt, main_column: true });
          }
        }
      }

      component.properties.forEach(function (column, index) {
        var headerObj = {
          type: column.type,
          headerLabel: index === 0 && component.view_type === 'segments' ? column.headerLabel : component.config.translations[_this4.get('currentLanguage')].headers[index],
          main_column: component.properties[index].main_column
        };
        if (headerObj.main_column || index === 0) {
          leftSideHeader.push(headerObj);
        } else {
          if (headerObj.hasOwnProperty('headerLabel') && typeof headerObj['headerLabel'] === 'string' && headerObj['headerLabel'].length > 70) {
            headerObj.headerLabel = headerObj.headerLabel.slice(0, 70) + '...';
          }
          rightSideHeader.push(headerObj);
        }
      });

      return { leftSideHeader: leftSideHeader, rightSideHeader: rightSideHeader };
    }),

    pages: computed('limit', 'content', 'tags', 'content_data', function () {
      var content = this.get('content_data'),
          component = this.get('content').component,
          limit = this.get('limit').key,
          finalContent = [{ leftSideContent: [], rightSideContent: [] }];
      var pageIndex = 0;

      if (!content || content.length === 0) {
        this.set('isEmpty', true);
        return [];
      }

      // adding content for configured tables based on view_type field
      if (component.view_type === 'questions') {
        content = this._getQuestionsContent(content, component.config);
      }

      if (component.view_type === 'tags') {
        content = this._getTagsContent(content, component.config);
      }

      if (component.view_type === 'segments') {
        content = this._getSegmentsContent(content, component.config);
      }

      // computing object to left/right array format
      content.forEach(function (line, index) {
        var computedLeftLine = [],
            computedRightLine = [];

        line.forEach(function (column, colIndex) {
          var headerObj = _extends({}, column, { index: index, main_column: column.main_column || component.properties[colIndex] && component.properties[colIndex].main_column });

          if (headerObj.main_column) {
            computedLeftLine.push(headerObj);
          } else {
            computedRightLine.push(headerObj);
          }
        });

        finalContent[pageIndex].leftSideContent.push(computedLeftLine);
        finalContent[pageIndex].rightSideContent.push(computedRightLine);
        if (limit !== -1 && (index + 1) % limit === 0 && index !== 0 && index !== content.length - 1) {
          pageIndex += 1;
          finalContent.push({ leftSideContent: [], rightSideContent: [] });
        }
      });
      return finalContent;
    }),

    clickableLine: computed('content', function () {
      var content = this.get('content');

      return Object.keys(this.get('popups')).includes(content.component.view_type) || content.component.view_type === '';
    }),

    popupEnabled: computed('content', function () {
      var content = this.get('content');

      return Object.keys(this.get('popups')).includes(content.component.view_type);
    }),

    // Popups automatically triggered by array view_type field
    questionsListPopupToggle: false,
    tagsListPopupToggle: false,

    popups: {
      'questions-breakdown': 'questionsListPopupToggle',
      'tags-breakdown': 'tagsListPopupToggle'
    },

    questionsIcons: {
      'rating': 'star_outlined',
      'long_text_input': 'comment_text_outlined',
      'short_text_input': 'comment_text_outlined',
      'single_select': 'checkmark_circle_outlined',
      'multiple_select': 'checkmark_circle_outlined'
    },

    _getQuestionsContent: function _getQuestionsContent(content, config) {
      var _this5 = this;

      var serializedQuestions = [],
          questIcons = this.get('questionsIcons');

      // Each line, inserting icon column + quest name column (config)
      content.forEach(function (line, lineIdx) {
        var ratingColumn = line.filter(function (q) {
          return q.question_rating;
        })[0],
            iconColumn = { type: 'icon', value: ratingColumn ? questIcons[ratingColumn.value.sub_label] : undefined },
            questionNameColumn = { type: 'main-label', value: config.translations[_this5.get('currentLanguage')].questions[lineIdx] };

        serializedQuestions.push([]);
        serializedQuestions[lineIdx].push(iconColumn);
        serializedQuestions[lineIdx].push(questionNameColumn);

        line.forEach(function (elem) {
          // adding the others column
          if (elem.question_rating) {
            var sub_label = elem.value.sub_label ? elem.value.sub_label : 'not_found';
            elem.value.sub_label = _this5.get('i18n').t('components.reports.array.questions.types.' + sub_label);
          }
          serializedQuestions[lineIdx].push(elem);
        });
      });
      return serializedQuestions;
    },

    _getTagsContent: function _getTagsContent(content, config) {
      var serializedTags = [];

      // Each line, fill tags name column (config)
      content.forEach(function (line, lineIdx) {
        var idxColumn = { type: 'index', main_column: true };

        serializedTags.push([]);
        serializedTags[lineIdx].push(idxColumn);
        line.forEach(function (elem, idx) {
          if (idx === 0) {
            serializedTags[lineIdx].push(_extends({}, elem, { main_column: true }));
          } else {
            serializedTags[lineIdx].push(elem);
          }
        });
      });

      return serializedTags;
    },

    _getSegmentsContent: function _getSegmentsContent(content, config) {
      var serializedTags = [];
      var idxColumn = { type: 'index', main_column: true };

      // Each line, fill tags name column (config)
      content.forEach(function (line, lineIdx) {

        serializedTags.push([]);
        line.forEach(function (elem, idx) {
          if (idx === 0) {
            if (config.index) {
              serializedTags[lineIdx].push(idxColumn);
            }
            serializedTags[lineIdx].push(_extends({}, elem, { main_column: true }));
          } else {
            serializedTags[lineIdx].push(elem);
          }
        });
      });

      return serializedTags;
    },

    _scrollToTopAndQuery: function _scrollToTopAndQuery() {
      var opt = this.get('viewByOpts')[0];
      var content = this.get('content');
      if (this.get('selectedViewBy')) {
        this._queryData(this.get('querySegments')[this.get('selectedViewBy').toLowerCase()]);
      } else if (this.get('content.component.config.view_by')) {
        this._queryData(this.get('querySegments')[opt.toLowerCase()]);
      } else {
        this._queryData();
      }
      this.element.scrollIntoView({ behavior: "smooth" });
    },

    _toggleLineClickTrigger: function _toggleLineClickTrigger() {
      var content = this.get('content'),
          popupToggle = this.get('popups')[content.component.view_type];

      this.toggleProperty(popupToggle || '');
    },

    _closeNameModal: function _closeNameModal() {
      this.set('emptyModalIsOpened', false);
      this.set('nameModalIsOpened', false);
    },

    _closeProgressModal: function _closeProgressModal() {
      this.set('progressModalIsOpened', false);
    },

    actions: {
      changePagination: function changePagination(selected) {
        this.set('pageIndex', 1);
        this.set('limit', selected);
        this.set('content_data', []);
        this._scrollToTopAndQuery();
      },

      previousPage: function previousPage() {
        this.set('pageIndex', this.get('pageIndex') - 1);
      },

      nextPage: function nextPage() {
        this.set('pageIndex', this.get('pageIndex') + 1);
        if (this.get('pages.length') < this.get('pageIndex')) {
          this._scrollToTopAndQuery();
        }
      },

      lineClickTrigger: function lineClickTrigger() {
        this._toggleLineClickTrigger();
      },

      changeViewBy: function changeViewBy(selectedViewBy) {
        var content = this.get('content');
        this.set('content_data', []);
        this.set('pageIndex', 1);
        this.set('selectedViewBy', { text: selectedViewBy });
        this._queryData(this.get('querySegments')[selectedViewBy.toLowerCase()], content.component.properties.slice(1));
      },

      openNameModal: function openNameModal() {
        if (!this.get('isEmpty') && !this.get('isLoading')) {
          var _name = 'export_' + this.get('currentAccount.content.company.name') + '_diduenjoy';
          this.set('newName', _name);
          this.set('newFormat', 'xlsx');
          this.set('fileContent', 'displayed_view');
          this.set('nameModalIsOpened', true);
        } else {
          this.set('emptyModalIsOpened', true);
        }
      },

      closeNameModal: function closeNameModal() {
        this._closeNameModal();
      },

      closeProgressModal: function closeProgressModal() {
        this._closeProgressModal();
      },

      goToHistory: function goToHistory() {
        this.get('redirectAction')('companies.exports.history');
        this._closeProgressModal();
      },

      changeFormat: function changeFormat(format) {
        this.set('newFormat', format);
      },

      changeFileContent: function changeFileContent(fileContent) {
        this.set('fileContent', fileContent);
      },

      'export': function _export() {
        var _this6 = this;

        var dataQueryForExport = this.get('dataQueryForExport');
        var content = JSON.stringify({
          date_range: dataQueryForExport.get('date_range'),
          filters: dataQueryForExport.get('filters'),
          properties: dataQueryForExport.get('properties'),
          front_format: dataQueryForExport.get('front_format'),
          group: dataQueryForExport.get('group'),
          object_ids: dataQueryForExport.get('object_ids'),
          object_id: dataQueryForExport.get('object_id'),
          sort: dataQueryForExport.get('sort'),
          lvls: dataQueryForExport.get('lvls'),
          kind: dataQueryForExport.get('kind'),
          subkind: dataQueryForExport.get('subkind'),
          translations: dataQueryForExport.get('translations'),
          query_segments: dataQueryForExport.get('query_segments'),
          page_size: dataQueryForExport.get('page_size'),
          pagination: dataQueryForExport.get('pagination')
        });
        var export_type = this.get('newFormat');
        var content_type = this.get('fileContent');
        var file_file_name = this.get('newName');
        this.set('export_promise', this.get('store').createRecord('segment-table-export-request', {
          content: content,
          export_type: export_type,
          content_type: content_type,
          file_file_name: file_file_name
        }).save().then(function (export_request) {
          _this6.set('nameModalIsOpened', false);
          _this6.set('progressModalIsOpened', true);
        }));
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{report/-array}} array component
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */