define('due-dashboard/components/reports/-verbatims-list', ['exports', 'ember', 'due-dashboard/helpers/thousand-separator'], function (exports, _ember, _dueDashboardHelpersThousandSeparator) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-verbatims-list'],
    classNameBindings: ['layout'],

    store: service(),
    filters: service(),

    isLoading: true,
    isEmpty: false,
    verbatims: [],
    feedbackSearchText: '',

    settingsDropdownIsOpen: false,
    settingsDropdownOptions: computed(function () {
      return [{ title: this.get('i18n').t('words.sort_by'), key: 'sort', currentItem: 0, options: this.get('sortOptions') }, { title: this.get('i18n').t('words.search_language'), key: 'searchLanguage', currentItem: 0, options: this.get('searchLanguageOptions') }];
    }),

    init: function init() {
      this._super();
      this._queryVerbatims();
    },

    _queryVerbatims: function _queryVerbatims() {
      var _this = this;

      var page_size = arguments.length <= 0 || arguments[0] === undefined ? 50 : arguments[0];
      var page_number = arguments.length <= 1 || arguments[1] === undefined ? 1 : arguments[1];

      if (this.get('canView')) {
        var globalFilters = this.get('filters.queryFilters').filter,
            reportFilters = this.get('reportFilters'),
            rawComponentFilters = this.get('content.component.filters'),
            componentFilters = typeof rawComponentFilters === 'object' ? rawComponentFilters : { surveys: rawComponentFilters },
            mergedFilters = this.get('filters.mergeQueryFilters')(globalFilters, reportFilters, componentFilters),
            serializedFilters = _.mapValues(mergedFilters, function (_ref, key) {
          var _ref2 = _slicedToArray(_ref, 1);

          var f = _ref2[0];

          var pluralFilters = ['segments', 'surveys', 'languages', 'sentiments', 'tags', 'tags_sentiments'];

          return pluralFilters.includes(key) ? f.join(',') : f[0];
        }),
            query = { filter: serializedFilters };

        query.filter.search_language = this.get('searchLanguage').key;
        query.filter.search_keyword = this.get('feedbackSearchText');
        query.page = { number: page_number, size: page_size };
        query.sort = this.get('sort').key;
        var promise = this.get('store').query('verbatim', query);
        this.set('isLoading', true);

        promise.then(function (verbatims) {
          var meta = verbatims.get('meta');
          _this.set('current_page', meta.current_page);
          _this.set('next_page', meta.next_page);
          _this.set('page_count', meta.page_count);
          _this.set('total_count', _dueDashboardHelpersThousandSeparator['default'].compute(meta.total_count));
          _this.set('verbatims', verbatims);

          _this.set('isLoading', false);
          _this.set('isEmpty', verbatims.toArray().length === 0);
        })['catch'](function (error) {
          _this.set('isEmpty', true);
        });
      } else {
        this.set('isEmpty', true);
      }
    },

    sortOptions: computed(function () {
      return [{ key: 'most_recent', text: this.get('i18n').t('components.reports.array.sorts.most_recent') }, { key: 'comment_size', text: this.get('i18n').t('components.reports.array.sorts.comment_size') }];
    }),
    sort: computed(function () {
      return { key: 'most_recent' };
    }),

    searchLanguageOptions: computed(function () {
      return [{ key: 'translated_language', text: this.get('i18n').t('words.translated_verbatim') + ' (' + this.get('currentAccount.content.language.tag') + ')' }, { key: 'original_language', text: this.get('i18n').t('words.original_verbatim') }];
    }),
    searchLanguage: computed(function () {
      return { key: 'translated_language' };
    }),

    limits: computed(function () {
      return [{ key: 10, text: '10' }, { key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }];
    }),

    limit: { key: 50, text: '50' },

    pageIndex: 0,

    page: computed('pages', 'limit', 'pageIndex', function () {
      var pages = this.get('pages'),
          pageIndex = this.get('pageIndex');

      return pages[pageIndex];
    }),

    leftArrowDisabled: computed('current_page', 'pages', function () {
      return this.get('current_page') === 1;
    }),

    rightArrowDisabled: computed('current_page', 'pages', function () {
      return this.get('current_page') === this.get('page_count');
    }),

    nbLeftColumns: computed('page', function () {
      return this.get('page').leftSideContent[0].length;
    }),

    pages: computed('limit', 'content', 'verbatims', function () {
      var verbatims = this.get('verbatims'),
          limit = this.get('limit').key,
          finalContent = [[]];
      var pageIndex = 0;

      verbatims.forEach(function (verbatim, index) {
        finalContent[pageIndex].push(verbatim);
        if (limit !== -1 && (index + 1) % limit === 0 && index !== 0 && index !== verbatims.length - 1) {
          pageIndex += 1;
          finalContent.push([]);
        }
      });
      return finalContent;
    }),

    title: computed('content', function () {
      return this.get('content.component.config.translations')[this.get('currentLanguage')].title;
    }),

    _scrollToTop: function _scrollToTop() {
      this.element.scrollIntoView(true, { behavior: "smooth" });
    },

    reloadObserver: observer('reload', 'filters.tags.[]', function () {
      var _this2 = this;

      _ember['default'].run.next(function (_) {
        return _this2._queryVerbatims();
      });
    }),

    actions: {
      changePagination: function changePagination(selected) {
        this.set('limit', selected);
        this._queryVerbatims(selected.key, 0);
        this._scrollToTop();
      },

      previousPage: function previousPage() {
        this._queryVerbatims(this.get('limit').key, this.get('current_page') - 1);
        this._scrollToTop();
      },

      nextPage: function nextPage() {
        this._queryVerbatims(this.get('limit').key, this.get('current_page') + 1);
        this._scrollToTop();
      },

      sortVerbatims: function sortVerbatims(selected) {
        this.set('sort', selected);
      },

      onSelectOption: function onSelectOption(selectedSetting) {
        var searchValue = this.get('feedbackSearchText');

        if (this.get('current_page') !== 1) this.set('current_page', 1);
        this.set(selectedSetting.groupKey, { key: selectedSetting.key });
        this.set('settingsDropdownOptions.' + selectedSetting.groupIndex + '.currentItem', selectedSetting.index);
        if (selectedSetting.groupKey === 'sort' || searchValue.length > 0) this._queryVerbatims(this.get('limit').key, this.get('current_page'));
      },

      debouncedSearchText: function debouncedSearchText() {
        if (this.get('settingsDropdownIsOpen')) this.set('settingsDropdownIsOpen', false);
        if (this.get('current_page') !== 1) this.set('current_page', 1);
        _ember['default'].run.debounce(this, this._queryVerbatims, this.get('limit').key, this.get('current_page'), 300);
      },

      toggleSettingsDropdownState: function toggleSettingsDropdownState() {
        this.toggleProperty('settingsDropdownIsOpen');
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{report/-array}} array component
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */