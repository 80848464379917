define('due-dashboard/services/filters', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  // import moment from 'moment';

  /* global _ */

  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Service.extend({

    i18n: service(),

    resetFilters: function resetFilters() {
      this.setProperties({
        search_keyword: "",
        surveys: [],
        segments: {},
        profiles: [],
        languages: [],
        tags: [],
        sentiments: [],
        tags_sentiments: [],
        excluded_tags: [],
        comment: 'both',
        tagged: 'both',
        deleted: 'not_deleted',
        resolved: 'both',
        completed: 'all',
        additionalFilters: []
      });
    },

    getFiltersCopy: function getFiltersCopy() {
      var ret = _.clone(this.getProperties('startDate', 'endDate', 'locale', 'comment', 'completed', 'deleted', 'resolved', 'search_keyword', 'profiles', 'segments', 'surveys', 'languages', 'tagged', 'tags', 'excluded_tags', 'sentiments', 'tags_sentiments'));
      ret.surveys = _.clone(ret.surveys);
      ret.profiles = _.clone(ret.profiles);
      ret.segments = _.clone(ret.segments);
      ret.languages = _.clone(ret.languages);
      ret.tags = _.clone(ret.tags);
      ret.excluded_tags = _.clone(ret.excluded_tags);
      ret.sentiments = _.clone(ret.sentiments);
      ret.tags_sentiments = _.clone(ret.tags_sentiments);
      return ret;
    },

    setFilters: function setFilters(params) {
      this.setProperties(params);
    },

    hasFilter: false,
    isReseting: false,
    fromUrl: false,
    additionalFilters: [],

    //
    // SEARCH KEYWORD FILTER
    //

    search_keyword: "",

    //
    // SURVEY FILTER
    //

    surveys: [],

    //
    // SEGMENTS FILTER
    //

    segments: {},

    //
    // DATE FILTER
    //

    startDate: computed(function () {
      return moment().subtract(30, 'days').startOf('day').toDate();
    }),

    endDate: computed(function () {
      return moment().endOf('day').toDate();
    }),

    pickedButton: null,

    //
    // PROFILE FILTER
    //

    profiles: [],

    //
    // COMMENTS FILTER
    //

    comment: 'both',

    //
    // COMMENTS FILTER
    //

    tagged: 'both',

    //
    // COMPLETED FILTER
    //

    completed: 'all',

    //
    // DELETED FILTER
    //

    deleted: 'not_deleted',

    //
    // RESOLVED FILTER
    //

    resolved: 'both',

    // LANGUAGES FILTER
    //

    languages: [],

    //
    // INCLUDED TAGS IDS FILTER
    //
    tags: [],

    //
    // EXCLUDED TAGS IDS FILTER
    //
    excluded_tags: [],

    //
    // SENTIMENTS FILTER
    //
    sentiments: [],

    //
    // TAGS SENTIMENTS FILTER
    //
    tags_sentiments: [],

    queryFilters: computed('startDate', 'endDate', 'profile', 'comment', 'completed', 'deleted', 'tagged', 'resolved', 'surveys', 'search_keyword', 'segments.[]', 'i18n.locale', 'languages', 'tags', 'excluded_tags', 'sentiments', 'tags_sentiments', function () {
      var start_date = this.get('startDate');
      var end_date = this.get('endDate');
      var client_profile = this.get('profiles').mapBy('name').join();
      var comment = this.get('comment');
      var completed = this.get('completed');
      var deleted = this.get('deleted');
      var tagged = this.get('tagged');
      var resolved = this.get('resolved');
      var surveys = this.get('surveys').mapBy('id').join(',');
      var segments = _.map(this.get('segments'), function (v, k) {
        return k + ':' + v;
      }).join(',');
      var search_keyword = this.get('search_keyword');
      var locale = this.get('i18n.locale').toUpperCase();
      var languages = this.get('languages').mapBy('id').join(',');
      var tags = this.get('tags').mapBy('id').join(',');
      var excluded_tags = this.get('excluded_tags').mapBy('id').join(',');
      var sentiments = this.get('sentiments').mapBy('value').join();

      var tags_sentiments = [];
      Object.entries(this.get('tags_sentiments')).forEach(function (entry) {
        var _entry = _slicedToArray(entry, 2);

        var key = _entry[0];
        var value = _entry[1];

        if (value.length > 0) {
          value.forEach(function (a) {
            tags_sentiments.push(key + '_' + a);
          });
        } else {
          tags_sentiments.push(key);
        }
      });
      tags_sentiments = tags_sentiments.join(',');

      var filters = {};
      if (start_date) {
        filters.start_date = start_date;
      }
      if (end_date) {
        filters.end_date = end_date;
      }
      if (client_profile) {
        filters.client_profile = client_profile;
      }
      if (comment) {
        filters.comment = comment;
      }
      if (deleted) {
        filters.deleted = deleted;
      }
      if (tagged) {
        filters.tagged = tagged;
      }
      if (resolved) {
        filters.resolved = resolved;
      }
      if (completed) {
        filters.completed = completed;
      }
      if (surveys) {
        filters.surveys = surveys;
      }
      if (segments) {
        filters.segments = segments;
      }
      if (search_keyword) {
        filters.search_keyword = search_keyword;
      }
      if (locale) {
        filters.locale = locale;
      }
      if (languages) {
        filters.languages = languages;
      }
      if (tags) {
        filters.tags = tags;
      }
      if (excluded_tags) {
        filters.excluded_tags = excluded_tags;
      }
      if (sentiments) {
        filters.sentiments = sentiments;
      }
      if (tags_sentiments) {
        filters.tags_sentiments = tags_sentiments;
      }
      return { filter: filters };
    }).volatile().readOnly(),

    mergeQueryFilters: function mergeQueryFilters(dashboardFilters, reportFilters, componentFilters) {
      var segment_regexp = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}):(.*?)(?=[,][0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}:|$)/g;
      var mergedFilters = {};

      var segmentsFilters = {};
      var surveysFilters = null;
      [dashboardFilters, reportFilters, componentFilters].forEach(function (filterLevel) {
        Object.entries(filterLevel).forEach(function (filter) {
          var filterName = filter[0];
          var filterValue = typeof filter[1] === 'object' ? filter[1].toString() : filter[1];

          if (filterName === 'segments') {
            [].concat(_toConsumableArray(filterValue.matchAll(segment_regexp))).forEach(function (splitted_filter) {
              var segment_id = splitted_filter[1];
              var segment_values = splitted_filter[2].split(',');
              if (!segmentsFilters[segment_id]) {
                segmentsFilters[segment_id] = segment_values;
              } else {
                segmentsFilters[segment_id] = segmentsFilters[segment_id].concat(segment_values).uniq();
              }
            });
          } else if (filterName === 'surveys') {
            (function () {
              var surveys_ids = [];
              if (typeof filterValue === 'string') {
                surveys_ids = filterValue.split(',');
              } else {
                surveys_ids = filterValue;
              }
              if (surveysFilters === null) {
                surveysFilters = surveys_ids;
              } else {
                surveysFilters = surveysFilters.filter(function (value) {
                  return surveys_ids.includes(value);
                });
              }
            })();
          } else {
            if (!mergedFilters[filterName]) {
              mergedFilters[filterName] = [];
            }
            mergedFilters[filterName].push(filterValue.split(','));
          }
        });
      });
      if (Object.keys(segmentsFilters).length > 0) {
        mergedFilters['segments'] = [[]];
        Object.keys(segmentsFilters).forEach(function (segment_id) {
          mergedFilters['segments'][0].push(segment_id + ':' + segmentsFilters[segment_id].join(','));
        });
      }
      if (surveysFilters !== null) {
        mergedFilters['surveys'] = [surveysFilters];
      }
      return mergedFilters;
    }
  });
});