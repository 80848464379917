define('due-dashboard/controllers/settings/users/new', ['exports', 'ember'], function (exports, _ember) {
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var mapBy = _Ember$computed.mapBy;
  var empty = _Ember$computed.empty;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  var Controller = _ember['default'].Controller;
  var all = _ember['default'].RSVP.all;
  exports['default'] = Controller.extend({

    emails: alias('model.emails'),
    selectedProfile: alias('model.profile'),
    existingEmail: mapBy('model.existings_accounts', 'email'),
    emptyEmails: empty('emails'),
    samlOptions: [true, false],
    zoneOptions: [{ name: 'Europe' }, { name: 'China' }],
    selectedZone: 'Europe',

    selectedSegments: [],
    availableSegments: alias('model.available_segments'),

    languages: computed('model.languages', function () {
      return this.get('model.languages').map(function (l) {
        return { name: l.get('name') + ' (' + l.get('tag') + ')' };
      });
    }),

    samlEnabled: computed('model.saml_enabled', {
      get: function get() {
        if (this.get('model.saml_enabled') === undefined) {
          this.set('model.saml_enabled', false);
        }
        return this.get('model.saml_enabled');
      }, set: function set(_, value) {
        this.set('model.saml_enabled', value);
        return value;
      }
    }),

    formatted_segments: computed('selectedSegments', function () {
      var formatted_segments = {};
      this.get('selectedSegments').forEach(function (segment) {
        formatted_segments[segment.get('id')] = segment.get('values');
      });
      return formatted_segments;
    }),

    readOnlySSO: computed('currentAccount.content.company.saml_enabled', function () {
      return this.get('currentAccount.content.company.saml_enabled') ? '' : 'read-only';
    }),

    selectedLanguage: computed('model.languages', function () {
      return this.get('model.languages.firstObject');
    }),

    selectedLanguageText: computed('selectedLanguage', function () {
      var selectedLanguage = this.get('selectedLanguage');
      return selectedLanguage.get('name') + ' (' + selectedLanguage.get('tag') + ')';
    }),

    canChangeZone: computed('currentAccount.content.company.replicate_to_china', 'model.profile', function () {
      return this.get('currentAccount.content.company.replicate_to_china') && this.get('model.profile') && !['admin', 'manager', 'read_only'].includes(this.get('model.profile.title'));
    }),

    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    _save_accounts: function _save_accounts() {
      var _this = this;

      var account_custom_profile_right = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      account_custom_profile_right = account_custom_profile_right || this.get('selectedProfile._internalModel.record');
      this.set('saveAccountsPromise', all(this.get('emails').map(function (email) {
        return _this.store.createRecord('account', {
          email: email,
          company: _this.get('currentAccount.content.company'),
          profile: account_custom_profile_right ? 'custom' : _this.get('selectedProfile.title') || 'manager',
          account_custom_profile_right: account_custom_profile_right,
          language: _this.get('selectedLanguage'),
          zone: _this.get('selectedZone'),
          saml_enabled: _this.get('samlEnabled'),
          segments: _this.get('formatted_segments')
        }).save();
      })).then(function () {
        _this.setProperties({ show_new_profile: null, profile: null, selectedProfile: null, emails: [], samlEnabled: false, selectedSegments: [] });
        _this.transitionToRoute('settings.users.index');
      }));
    },

    actions: {

      cancelProfile: function cancelProfile() {
        getOwner(this).lookup('controller:application').transitionToRoute('settings.users.index');
      },

      createProfile: function createProfile() {
        if (!this.get('profile')) {
          var _name = '';
          if (this.get('emails.firstObject')) {
            _name = this.get('emails.length') > 1 ? '' : this.get('i18n').t('settings.groups.default_name') + ' ' + this.get('emails.firstObject');
          }
          this.set('profile', this.store.createRecord('account_custom_profile_right', { name: _name }));
        }
      },

      profileChanged: function profileChanged(choosed) {
        this.set('selectedProfile', choosed);
        this.set('show_new_profile', get(choosed, 'type') === 'new_profile');
      },

      updateAccountsZone: function updateAccountsZone(zone) {
        this.set('selectedZone', zone.name);
        this.set('model.zone', zone.name);
      },

      sendInvitation: function sendInvitation() {
        var _this2 = this;

        var account_custom_profile_right = this.get('show_new_profile') && this.get('profile');
        if (account_custom_profile_right) {
          this.set('saveAccountsPromise', account_custom_profile_right.save().then(function (acc) {
            _this2._save_accounts(acc);
          }));
        } else {
          this._save_accounts();
        }
      },

      checkEmail: function checkEmail(email) {
        var current_count = this.get('emails').length + this.get('existingEmail').length + 1;
        if (this.get('currentAccount.content.company.max_account') != 0 && this.get('currentAccount.content.company.max_account') < current_count) {
          return "max_account";
        }
        if (!this.validateEmail(email)) {
          return "invalid_format";
        }
        if (this.get('existingEmail').indexOf(email) !== -1) {
          return "already_taken";
        }
        return null;
      },

      updateAccountsDefaultLanguage: function updateAccountsDefaultLanguage(language) {
        var newLanguage = this.get('model.languages').find(function (l) {
          return l.get('name') === language.name.split(' (')[0];
        });
        this.set('selectedLanguage', newLanguage);
        this.set('model.language', newLanguage);
      },

      toggleIcon: function toggleIcon(onToggle) {
        onToggle();
      },

      addSegmentClick: function addSegmentClick() {
        this.set('showSegmentsDropdown', true);
        this.get('segmentsDropDownApi').actions.open();
      },

      addSegment: function addSegment(segment) {
        segment.set('values', []);
        this.get('selectedSegments').addObject(segment);
      },

      removeSegment: function removeSegment(segment) {
        this.get('selectedSegments').removeObject(segment);
      },

      addSegmentValues: function addSegmentValues(segment, values) {
        this.set('selectedSegmentsTouched', true);
        var newAccountSegments = this.get('newAccountSegments');
        newAccountSegments[segment.get('id')] = values;
        this.set('newAccountSegments', newAccountSegments);
      },

      setAddSegmentController: function setAddSegmentController(dropdownApi) {
        this.set('segmentsDropDownApi', dropdownApi);
      }
    }
  });
});