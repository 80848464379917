define('due-dashboard/components/dashboard-filters', ['exports', 'ember', 'moment', 'due-dashboard/models/segment'], function (exports, _ember, _moment, _dueDashboardModelsSegment) {
  /* global _ */

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  var debounce = _ember['default'].run.debounce;
  var copy = _ember['default'].copy;
  exports['default'] = Component.extend({

    filters: service(),
    allSegments: [],
    remainingSegment: computed.setDiff('allSegments', 'selectedSegments'),
    elementId: 'dashboard-filters',
    filterModalIsOpened: false,
    isFiltering: false,
    hasFilter: computed.alias('filters.hasFilter'),
    isReseting: computed.alias('filters.isReseting'),
    displayList: false,
    allSearches: [],
    hasBeenModify: false,
    triggerDropDownReset: false,
    companySearches: computed.setDiff('realSearches', 'accountSearches'),
    currentSearch: null,
    previousSearch: null,
    modifying: false,
    deleting: false,
    tempName: null,
    searchBarDisplay: false,
    hasNpsSurveys: computed.alias('currentAccount.content.company.content.has_nps_surveys'),

    _setFiltersFromUrl: function _setFiltersFromUrl() {
      var _this = this;

      var dashFilters = this.get('filters.queryFilters').filter,
          mandatoryFilters = this.get_default_filters();

      var list = {
        'comment': 'comment',
        'deleted': 'deleted',
        'resolved': 'status',
        'tagged': 'tag',
        'surveys': 'survey',
        'languages': 'language',
        'profile': 'client_profile',
        'sentiments': 'sentiment',
        'segments': 'segment'
      };
      var currentFilters = [];

      Object.keys(dashFilters).map(function (filter) {
        if (list[filter]) {
          if (filter === 'segments') {
            (function () {
              var segments = _this.get('filters.segments');
              Object.keys(segments).map(function (seg) {
                currentFilters.push({ type: 'segment', value: seg + ':' + segments[seg] });
              });
            })();
          } else {
            currentFilters.push({ type: list[filter], value: dashFilters[filter] });
          }
        }
      });

      mandatoryFilters.forEach(function (mandatoryFilter) {
        if (currentFilters.filter(function (filter) {
          return filter.type === mandatoryFilter.type;
        }).length === 0) {

          if (filter.type === 'profile' && _this.get('hasNpsSurveys')) {
            currentFilters.push(mandatoryFilter);
          } else if (filter.type !== 'profile') {
            currentFilters.push(mandatoryFilter);
          }
        }
      });
      this.get('filters').set('fromUrl', false);
      this.set('currentFilters', currentFilters);
      this._saveCurrent(currentFilters);
    },

    _saveCurrent: function _saveCurrent(_filters) {
      var filters = _filters || this.get('currentFilters');

      this.set('filters.currentFilters', copy(filters, true));
    },

    _loadCurrent: function _loadCurrent() {
      var saved = this.get('filters.currentFilters');

      if (saved) {
        this.set('currentFilters', saved);
      }
    },

    filtersChanged: observer('filters.additionalFilters', function () {
      var current = this.get('currentFilters'),
          add = this.get('filters.additionalFilters');

      if (add.includes('tags')) {
        var curFilter = current.find(function (f) {
          return f.type == 'tag';
        }),
            tagFilters = this.get('filters.tags').mapBy('id');

        if (curFilter) {
          curFilter.valueTag = tagFilters;
        } else {
          current.push({ type: 'tag', value: this.get('filters.tagged'), valueTag: tagFilters });
        }
        this.set('currentFilters', current);
        this.notifyPropertyChange('currentFilters');
        this._saveCurrent();
      }
    }),

    currentFilters: computed(function () {
      return this.get_default_filters();
    }),

    allFilters: ['status', 'comment', 'completed', 'deleted', 'profile', 'survey', 'tag', 'language', 'sentiment'],

    addableFilters: computed('currentFilters', function () {
      var _this2 = this;

      var finalFilters = [];
      var usedFilters = this.get('currentFilters').map(function (filter) {
        return filter.type;
      });

      this.get('allFilters').forEach(function (filter) {
        var index = usedFilters.indexOf(filter);
        if (index < 0 && filter !== 'profile') {
          finalFilters.push(filter);
        } else if (index < 0 && filter === 'profile' && _this2.get('hasNpsSurveys')) {
          finalFilters.push(filter);
        }
      });
      return finalFilters.map(function (filter) {
        return { action: filter, textKey: 'digest.filter.' + filter };
      });
    }),

    listedFilters: computed('addableFilters', 'allSegments', function () {
      var _this3 = this;

      var ret = [];
      var segmentsList = [];
      this.get('remainingSegment').toArray().forEach(function (attr) {
        return segmentsList.push({
          type: 'segment',
          name: attr.get('name'),
          title: attr.get('name'),
          segmentId: attr.id
        });
      });
      ret = this.get('addableFilters').toArray().map(function (attr) {
        return {
          type: attr.action,
          name: _this3.get('i18n').t(attr.textKey),
          title: _this3.get('i18n').t(attr.textKey)
        };
      });
      ret.push({
        groupName: { title: this.get('i18n').t('digest.filter.segment'), name: this.get('i18n').t('digest.filter.segment'), type: 'segment' },
        options: segmentsList
      });
      this.reloadDropDown();
      return ret;
    }),

    reloadDropDown: function reloadDropDown() {
      var _this4 = this;

      debounce(this, function () {
        _this4.toggleProperty('triggerDropDownReset');
      }, 1000);
    },

    displayButton: computed('addableFilters', function () {
      if (this.get('addableFilters').length > 0) {
        return true;
      }
      return false;
    }),

    selectedSegments: computed('allSegments', 'currentFilters', function () {
      var segmentIds = this.get('currentFilters').filter(function (f) {
        return f.type == 'segment';
      }).map(function (s) {
        return s.segmentId;
      });
      return this.get('allSegments').toArray().filter(function (a) {
        return segmentIds.includes(a.id);
      });
    }),

    realSearches: computed('allSearches', function () {
      return this.get('allSearches').toArray().filter(function (a) {
        return a.get('account_id') != null;
      });
    }),

    accountSearches: computed('realSearches', function () {
      var accountId = this.get('currentAccount.content.id');
      return this.get('realSearches').toArray().filter(function (a) {
        return a.get('account_id') == accountId;
      });
    }),

    displaySaveNew: computed('hasBeenModify', 'currentSearch', function () {
      if (!this.get('currentSearch') || this.get('hasBeenModify')) {
        return true;
      }
      return false;
    }),

    isDeleting: computed('deleting', function () {
      if (this.get('deleting')) {
        return ' red';
      }
      return '';
    }),

    nameValid: computed('displayNameError', function () {
      if (this.get('displayNameError')) {
        return 'error';
      }
      return '';
    }),

    query_params: {},

    init: function init() {
      var _this5 = this;

      if (this.get('filters.fromUrl')) {
        this._setFiltersFromUrl();
      }
      this.store.query('segment', { filter: { all: false, display: 'filter' }, page: { number: 1, size: 200 } }).then(function (allSegments) {
        _this5.set('allSegments', allSegments.toArray());
        _this5.set('_allSegments', _.clone(_this5.get('sortedEnabledSegments').toArray()));
      });
      this.store.findAll('search').then(function (searches) {
        _this5.set('allSearches', searches);
        if (_this5.get('filters.searchId')) {
          var search = searches.toArray().find(function (s) {
            return s.id == _this5.get('filters.searchId');
          });
          _this5.set('currentSearch', search);
        }
        _this5._loadCurrent();
      });
      var act = this.get('registerApi');
      if (act) {
        act(this);
      }
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('displayList', true);
    },

    DISABLED_SEGMENT_FILTERS: ['MC_CID', 'MC_EID', 'SPM_KEY', 'UTM_SOURCE', 'UTM_CAMPAIGN', 'UTM_CONTENT', 'UTM_MEDIUM', 'UTM_TERM', 'SCHEDULED_AT'],

    enabledSegments: computed.filter('allSegments', function (s) {
      return !this.DISABLED_SEGMENT_FILTERS.includes(s.get('name').toUpperCase());
    }),

    sortedEnabledSegments: computed.sort('enabledSegments.[]', _dueDashboardModelsSegment['default'].sort),

    minDate: computed(function () {
      return (0, _moment['default'])("2000-01-01").startOf('day').toDate();
    }),
    maxDate: computed(function () {
      return (0, _moment['default'])().endOf('day').toDate();
    }),

    defaultStartDate: computed(function () {
      return (0, _moment['default'])().startOf('day').subtract(30, 'days').toDate();
    }),

    startDate: computed.or('filters.startDate', 'defaultStartDate'),
    endDate: computed.or('filters.endDate', 'maxDate'),
    pickedButton: computed.alias('filters.pickedButton'),

    availableProfiles: A([{ name: 'detractor', icon: '/assets/images/icons/detractor.png' }, { name: 'passive', icon: '/assets/images/icons/passive.png' }, { name: 'promoter', icon: '/assets/images/icons/promoter.png' }]),

    _set_rb_point: function _set_rb_point() {
      this.set('_rb_point_filters', this.get('filters').getFiltersCopy());
      this.set('_rb_current_filters', copy(this.get('currentFilters'), true));
    },

    _rollback: function _rollback() {
      if (this.get('isFiltering')) {
        return;
      }
      var rb_point = this.get('_rb_point_filters');
      this.set('currentFilters', copy(this.get('_rb_current_filters'), true));
      this.get('filters').setFilters(rb_point);
    },

    filterButtonText: computed('i18n.locale', 'hasFilter', function () {
      if (this.get('hasFilter')) {
        return this.get('i18n').t('sidebar.filters.edit');
      } else {
        return this.get('i18n').t('sidebar.filters.title');
      }
    }),

    get_default_filters: function get_default_filters() {
      var filters = [{ type: 'comment' }, { type: 'status' }];

      if (this.get('hasNpsSurveys')) {
        filters.push({ type: 'profile' });
      }
      filters.push({ type: 'survey' });
      return filters;
    },

    _searchSegments: function _searchSegments(term, resolve, reject) {
      this.store.query('segment', {
        filter: {
          all: false,
          name: term,
          display: 'filter'
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },

    toggleMobileFilterContent: function toggleMobileFilterContent() {
      if (this.get('searchBarDisplay')) {
        this.toggleProperty('searchBarDisplay');
      }
    },

    actions: {

      modifiedSearch: function modifiedSearch() {
        this.set('hasBeenModify', true);
      },

      openFiltersModal: function openFiltersModal() {
        if (this.get('closedSearch')) {
          this.set('currentSearch', this.get('closedSearch'));
        }
        if (this.get('isFiltering')) {
          this.set('forceKeepModalOpen', true);
        }
        this._set_rb_point();
        this.set('filterModalIsOpened', true);
        this.set('deleting', false);
      },

      closeFiltersModal: function closeFiltersModal() {
        this.set('currentSearch', null);
        this._rollback();
        this.set('filterModalIsOpened', false);
        this.set('modifying', false);
        this.set('displayNameError', false);
        this.set('deleting', false);
      },

      setAddFilterController: function setAddFilterController(remoteController) {
        this.set('addFilterController', remoteController);
      },

      newSearch: function newSearch() {
        this.get('filters').resetFilters();
        this.set('currentSearch', null);
        this.set('currentFilters', this.get_default_filters());
        this.toggleMobileFilterContent();
      },

      createSearch: function createSearch() {
        var newSearch = this.store.createRecord('search');
        if (this.get('currentSearch') && this.get('currentSearch.id')) {
          this.set('previousSearch', this.get('currentSearch'));
        }
        this.set('currentSearch', newSearch);
        this.set('tempName', '');
        this.set('tempShared', false);
        this.set('modifying', true);
        this.set('displayNameError', false);
      },

      setSearch: function setSearch(search) {
        this.set('deleting', false);
        this.set('modifying', false);
        this.set('displayNameError', false);
        this.set('hasBeenModify', false);
        this.set('currentFilters', copy(search.get('filters.data'), true));
        this.set('currentSearch', search);
        this.toggleMobileFilterContent();
      },

      modifySearch: function modifySearch() {
        this.set('deleting', false);
        this.set('previousSearch', this.get('currentSearch'));
        this.set('tempName', this.get('currentSearch.name'));
        this.set('tempShared', this.get('currentSearch.shared'));
        this.set('modifying', true);
        this.set('displayNameError', false);
      },

      cancelModify: function cancelModify() {
        if (this.get('previousSearch')) {
          this.set('currentSearch', this.get('previousSearch'));
        } else {
          this.set('currentSearch', null);
        }
        this.set('modifying', false);
        this.set('displayNameError', false);
      },

      tryToDelete: function tryToDelete() {
        this.set('deleting', true);
      },

      cancelDelete: function cancelDelete() {
        this.set('deleting', false);
      },

      deleteSearch: function deleteSearch() {
        this.set('previousSearch', null);
        this.get('allSearches').removeObject(this.get('currentSearch'));
        this.get('currentSearch').destroyRecord();
        this.notifyPropertyChange('allSearches');
        this.set('currentSearch', null);
        this.set('modifying', false);
        this.set('displayNameError', false);
      },

      saveSearch: function saveSearch() {
        var _this6 = this;

        if (!this.get('tempName') || this.get('tempName').length < 3) {
          this.set('displayNameError', true);
          this.set('isSaving', true);
          return;
        }
        var copiedFilters = copy(this.get('currentFilters'), true);
        this.set('currentSearch.filters', { data: copiedFilters });
        this.set('currentSearch.name', this.get('tempName'));
        this.set('currentSearch.shared', this.get('tempShared'));
        this.get('currentSearch').save().then(function (newSearch) {
          if (!_this6.get('allSearches').toArray().includes(newSearch)) {
            _this6.get('allSearches').addObject(newSearch);
          }
          _this6.notifyPropertyChange('allSearches');
          _this6.set('modifying', false);
          _this6.set('displayNameError', false);
          _this6.set('hasBeenModify', false);
        });
      },

      mutShared: function mutShared() {
        this.toggleProperty('tempShared');
      },

      addFilter: function addFilter(filter) {
        this.set('hasBeenModify', true);
        var filters = this.get('currentFilters');
        if (filter.type == 'segment') {
          filters.push({ type: filter.type, segmentId: filter.segmentId });
        } else {
          filters.push({ type: filter.type });
        }
        this.set('currentFilters', filters);
        this.notifyPropertyChange('currentFilters');
      },

      deleteFilter: function deleteFilter(filter) {
        this.set('hasBeenModify', true);
        this.get('currentFilters').removeObject(filter);
        this.notifyPropertyChange('currentFilters');
      },

      addFilterClick: function addFilterClick() {
        this.get('addFilterController').actions.open();
      },

      updateCustomDate: function updateCustomDate() {
        if (this.get('filters.dateRangeType') === "custom") {
          this.get('filtersUpdated')();
        }
      },

      searchSegments: function searchSegments(term) {
        var _this7 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          debounce(_this7, _this7._searchSegments, term, resolve, reject, 600);
        });
      },

      saveFilters: function saveFilters() {
        var _this8 = this;

        this.set('deleting', false);
        this.set('isFiltering', true);
        var ret = this.get('filtersUpdated')();
        this.get('closedSearch');
        if (this.get('forceKeepModalOpen')) {
          this.set('forceKeepModalOpen', false);
        }
        if (this.get('currentSearch')) {
          this.set('closedSearch', this.get('currentSearch'));
          this.set('filters.searchId', this.get('currentSearch.id'));
        } else {
          this.set('closedSearch', null);
        }
        this._saveCurrent();
        var stop = function stop() {
          _this8.set('hasFilter', true);
          if (!_this8.get('forceKeepModalOpen')) {
            _this8.set('filterModalIsOpened', false);
          }
          _this8.set('isFiltering', false);
        };

        if (ret && typeof ret.then === 'function') {
          ret.then(stop);
        } else {
          stop();
        }
      },

      rangePicked: function rangePicked(start, end, pickedButton) {
        var _this9 = this;

        this.get('filters').set('startDate', start);
        this.get('filters').set('endDate', end);
        this.get('filters').set('pickedButton', pickedButton);
        this.set('isFiltering', true);
        var ret = this.get('filtersUpdated')();
        if (ret && typeof ret.then === 'function') {
          ret.then(function () {
            return _this9.set('isFiltering', false);
          });
        } else {
          this.set('isFiltering', false);
        }
      },

      resetFilters: function resetFilters() {
        var _this10 = this;

        var defaultFilters = this.get_default_filters();
        this.set('deleting', false);
        this.set('isReseting', true);
        this.get('filters').resetFilters();
        this.set('filters.searchId', null);
        this.set('currentSearch', null);
        this.set('filters.currentFilters', null);
        this.set('currentFilters', defaultFilters);
        this.set('closedSearch', null);
        var ret = this.get('filtersUpdated')();
        if (ret && typeof ret.then === 'function') {
          ret.then(function () {
            _this10.set('filters.hasFilter', false);
            _this10.set('isReseting', false);
          });
        } else {
          this.set('filters.hasFilter', false);
          this.set('isReseting', false);
        }
      },

      toggleState: function toggleState() {
        this.toggleProperty('searchBarDisplay');
      }
    }

  });
});