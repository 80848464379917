define('due-dashboard/components/v2/libraries/surveys/navbar/elements/elements-content', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content'],

    hoverItemIndex: -1,
    newElementDropdownIsOpen: false,
    newConditionDropdownIsOpen: false,
    deleteAlertIsOpen: false,
    elementIndexToDelete: -1,
    clicks: 0,
    selectedElementNameIndex: -1,
    conditionSourceFilterInput: '',
    stepName: null,

    CONDITION_KINDS: ['contains', 'does_not_contains', 'is_any_of', 'is_not_one_of'],

    CONDITIONABLE_QUESTION_TYPES: ['rating', 'short_text_input', 'long_text_input', 'single_select', 'multiple_select'],

    dropdownConditionActions: computed(function () {
      return [{ color: ' ', icon: 'tio-copy', action: 'duplicateCondition', text: this.get('i18n').t('words.duplicate_maj') }, { color: ' ', icon: 'tio-delete', action: 'deleteCondition', text: this.get('i18n').t('words.delete_maj') }];
    }),

    dropdownButtonActions: computed(function () {
      return [{ color: ' ', icon: 'tio-copy', action: 'duplicateElement', text: this.get('i18n').t('words.duplicate_maj') }, { color: ' ', icon: 'tio-delete', action: 'openDeleteElementAlert', text: this.get('i18n').t('words.delete_maj') }];
    }),

    conditionSourceOptions: computed('group.segmentsList', 'group.questionsList', 'conditionSourceFilterInput', function () {
      var conditionableQuestionsTypes = this.get('CONDITIONABLE_QUESTION_TYPES');
      var filter = this.get('conditionSourceFilterInput');
      var filterParentKey = this.get('conditionSourceFilterKey');
      var questions = filter.length ? this.get('group.questionsList').filter(function (question) {
        return question.text.toLowerCase().includes(filter.toLowerCase()) && conditionableQuestionsTypes.includes(question.kind);
      }) : this.get('group.questionsList').filter(function (question) {
        return conditionableQuestionsTypes.includes(question.kind);
      });
      var segments = filter.length ? this.get('group.segmentsList').filter(function (segment) {
        return segment.text.toLowerCase().includes(filter.toLowerCase());
      }) : this.get('group.segmentsList');
      if (filter.length > 0) {
        return filterParentKey === 'segments' ? segments : questions.map(function (question) {
          return { text: question.step + 1 + '.' + (question.element + 1) + ' ' + question.text, type: 'question', step: question.step, element: question.element };
        });
      } else {
        return [{ text: this.get('i18n').t('words.segments').string, options: segments, key: 'segments' }, { text: this.get('i18n').t('words.questions').string, key: 'questions', options: questions.map(function (question) {
            return { text: question.step + 1 + '.' + (question.element + 1) + ' ' + question.text, type: 'question', step: question.step, element: question.element };
          }) }];
      }
    }),

    conditionsDataMapping: {
      'answer_in': {
        answer_step: 0,
        answer_element: 0,
        answer_in: []
      },
      'segment_value_in': {
        segment_name: '',
        segment_value_in: []
      },
      'segment_value_not_in': {
        segment_name: '',
        segment_value_not_in: []
      },
      'segment_value_contains': {
        segment_name: '',
        segment_value_contains: []
      },
      'segment_value_not_contains': {
        segment_name: '',
        segment_value_not_contains: []
      }
    },

    computedStepConditions: computed('object.conditions', function () {
      var _this = this;

      var object = this.get('object');
      if (!object) return [];

      return object.conditions.map(function (condition) {
        return {
          uuid: condition.uuid,
          kind: condition.kind,
          cmpType: _this._getCmpType(condition),
          cmpSource: _this._getCmpSource(condition),
          data: condition.data,
          stepIndex: '',
          elementIndex: ''
        };
      });
    }),

    _computeConditionKind: function _computeConditionKind(kind, condition) {
      if (condition.data.segment_name) {
        switch (kind) {
          case 'is_any_of':
            return 'segment_value_in';
          case 'is_not_one_of':
            return 'segment_value_not_in';
          case 'contains':
            return 'segment_value_contains';
          case 'does_not_contains':
            return 'segment_value_not_contains';
        }
      } else {
        return 'contains';
      }
    },

    _getCmpType: function _getCmpType(condition) {
      switch (condition.kind) {
        case 'answer_in':
          return 'is_any_of';
        case 'segment_value_in':
          return 'is_any_of';
        case 'segment_value_not_in':
          return 'is_not_one_of';
        case 'segment_value_contains':
          return 'contains';
        case 'segment_value_not_contains':
          return 'does_not_contains';
      }
    },

    _getCmpSource: function _getCmpSource(condition) {
      var questions = this.get('group.questionsList');

      if (condition.kind.includes('segment_value_')) {
        return { text: condition.data.segment_name };
      } else if (condition.kind === 'answer_in') {
        var _ret = (function () {
          var step = condition.data.answer_step;
          var element = condition.data.answer_element;
          var question = questions.filter(function (question) {
            return question.step === step && question.element === element;
          })[0].text;

          return {
            v: { text: step + 1 + '.' + (element + 1) + ' ' + question }
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    },

    _elementNameChanged: function _elementNameChanged(uuid, value) {
      var steps = this.get('steps');
      var stepIdx = this.get('selectedStepIndex');
      var elementIdx = steps[stepIdx]['survey_elements_attributes'].findIndex(function (element) {
        return element.uuid === uuid;
      });

      if (value && elementIdx !== undefined && steps[stepIdx]['survey_elements_attributes'][elementIdx].name !== value && value !== -1) {
        steps[stepIdx]['survey_elements_attributes'][elementIdx].name = value;
      }

      if (value === -1) {
        this.set('selectedElementNameIndex', -1);
      }
    },

    _changeSelectedElement: function _changeSelectedElement(uuid) {
      var stateToggleCallback = this.get('onStateChange');
      var steps = this.get('steps');
      var stepIdx = this.get('selectedStepIndex');
      var flatLvlIndex = steps[stepIdx].survey_elements_attributes.findIndex(function (elem) {
        return elem && elem.uuid === uuid;
      });
      var selectedElement = steps[stepIdx].survey_elements_attributes[flatLvlIndex];

      stateToggleCallback(selectedElement, flatLvlIndex);
    },

    _reorderStepsElementsConditions: function _reorderStepsElementsConditions(prevSteps, newElements) {
      var stepIdx = this.get('selectedStepIndex');
      var prevElems = prevSteps[stepIdx].survey_elements_attributes;

      // check if elements have moved
      var checkedElements = newElements.map(function (newElem, newIdx) {
        var oldIdx = prevElems.findIndex(function (prevElem) {
          if (newElem.uuid === prevElem.uuid) {
            return true;
          }
        });

        // find steps to edit after reorder
        var stepsConditionsToEdit = prevSteps.filter(function (step, stepIndex) {
          return step.conditions.filter(function (condition) {
            return condition.kind === 'answer_in' && condition.data.answer_step === stepIndex && condition.data.answer_element === oldIdx && !condition.has_been_cmptd;
          }).length > 0;
        });
        var elemConditionsToEdit = prevSteps.map(function (step) {
          return step.survey_elements_attributes.filter(function (element) {
            return element.conditions.filter(function (condition) {
              return condition.kind === 'answer_in' && condition.data.answer_element === oldIdx && !condition.has_been_cmptd;
            }).length > 0;
          });
        }).flat();
        var newElemsConditionsToEdit = newElements.filter(function (element) {
          return element.conditions.filter(function (condition) {
            return condition.kind === 'answer_in' && condition.data.answer_step === stepIdx && condition.data.answer_element === oldIdx && !condition.has_been_cmptd;
          });
        });

        // update steps conditions positions
        stepsConditionsToEdit.forEach(function (step) {
          step.conditions.forEach(function (condition) {
            if (condition.data.answer_element === oldIdx && condition.data.answer_step === stepIdx && !condition.has_been_cmptd) {
              condition.has_been_cmptd = true;
              condition.data.answer_element = newIdx;
            }
          });
        });
        // update elements conditions positions
        elemConditionsToEdit.forEach(function (element) {
          element.conditions.forEach(function (condition) {
            if (oldIdx !== newIdx && condition.data.answer_element === oldIdx) {
              condition.has_been_cmptd = true;
              condition.data.answer_element = newIdx;
            }
          });
        });
        newElemsConditionsToEdit.forEach(function (element) {
          element.conditions.forEach(function (condition) {
            if (oldIdx !== newIdx && condition.data.answer_element === oldIdx) {
              condition.has_been_cmptd = true;
              condition.data.answer_element = newIdx;
            }
          });
        });
        return newElem;
      });
      set(prevSteps[stepIdx], 'survey_elements_attributes', checkedElements);
      return prevSteps;
    },

    _updateChildsUuid: function _updateChildsUuid(element) {
      var _this2 = this;

      if (element.content.children) {
        element.content.children.forEach(function (subElement) {
          if (subElement.kind === 'group') {
            _this2._updateChildsUuid(subElement);
          }
          subElement.uuid = crypto.randomUUID();
        });
      }
    },

    _elementsHaveMoved: function _elementsHaveMoved(reorderedElements) {
      var steps = this.get('steps');
      var stepIdx = this.get('selectedStepIndex');
      var elements = steps[stepIdx].survey_elements_attributes;
      var response = false;

      reorderedElements.forEach(function (elem, index) {
        if (elem.uuid !== elements[index].uuid) {
          response = true;
        }
      });

      return response;
    },

    actions: {
      dispatchAction: function dispatchAction(action, arg) {
        var dispatcher = this.get('actionsDispatcher');

        if (dispatcher) {
          dispatcher(action, arg);
        }
      },

      dispatchActionLocally: function dispatchActionLocally(arg, action, callbackArgs) {
        if (action) {
          this.send(action, arg, callbackArgs);
        }
      },

      updateElementNameIndex: function updateElementNameIndex(index, reset) {
        this.set('selectedElementNameIndex', reset ? -1 : index);
        var saveCallback = this.get('group.autoSaveRevisionCallback');
        if (saveCallback && reset) {
          this.send('dispatchAction', saveCallback);
        }
      },

      onClickBackButton: function onClickBackButton() {
        var backToIndex = this.get('backToIndexCallback');

        if (backToIndex) {
          backToIndex();
        }
      },

      setHoverIndex: function setHoverIndex(index) {
        if (!this.isDestroyed) {
          this.set('hoverItemIndex', index);
        }
      },

      reorderElements: function reorderElements(_, _reorderElements) {
        if (this._elementsHaveMoved(_reorderElements)) {
          var steps = this.get('steps');
          var stepIdx = this.get('selectedStepIndex');
          var oldSteps = copy(steps, true);
          set(steps[stepIdx], 'survey_elements_attributes', _reorderElements);
          var computedSteps = this._reorderStepsElementsConditions(oldSteps, _reorderElements);

          this.send('dispatchAction', 'refreshSteps', computedSteps);
        }
      },

      onElementNameChanged: function onElementNameChanged(uuid, value) {
        // same reason as steps-content.js -> onStepNameChanged
        // le debounce cause un reset du component
        this._elementNameChanged(uuid, value);
      },

      openDeleteElementAlert: function openDeleteElementAlert(uuid) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elementIdx = steps[stepIdx]['survey_elements_attributes'].findIndex(function (element) {
          return element.uuid === uuid;
        });

        this.set('elementIndexToDelete', elementIdx);
        this.set('deleteAlertIsOpen', true);
      },

      duplicateElement: function duplicateElement(uuid) {
        var _this3 = this;

        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');

        var element = steps[stepIdx]['survey_elements_attributes'].filter(function (element) {
          return element.uuid === uuid;
        })[0];
        var elementIdx = steps[stepIdx]['survey_elements_attributes'].findIndex(function (element) {
          return element.uuid === uuid;
        });
        var duplicateElement = copy(element, true);

        duplicateElement.name += ' - copy';
        duplicateElement.uuid = crypto.randomUUID();
        duplicateElement.position += 1;

        if (duplicateElement.content.children && duplicateElement.content.children.length) {
          duplicateElement.content.children.forEach(function (element) {
            if (element.kind === 'group') {
              _this3._updateChildsUuid(element);
            }
            element.uuid = crypto.randomUUID();
          });
        }
        steps[stepIdx]['survey_elements_attributes'].splice(elementIdx + 1, 0, duplicateElement);
        set(steps[stepIdx], 'survey_elements_attributes', steps[stepIdx]['survey_elements_attributes']);
        this.set('hoverItemIndex', -1);
        this.send('dispatchAction', 'refreshSteps', steps);
      },

      deleteElement: function deleteElement() {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var elemIndex = this.get('elementIndexToDelete');

        steps[stepIdx]['survey_elements_attributes'].splice(elemIndex, 1);
        this.send('dispatchAction', 'refreshSteps');
        this.set('hoverItemIndex', -1);
        this.set('deleteAlertIsOpen', false);
      },

      cancelDeleteElement: function cancelDeleteElement() {
        this.set('elementIndexToDelete', -1);
        this.set('hoverItemIndex', -1);
        this.set('deleteAlertIsOpen', false);
      },

      editElementName: function editElementName(uuid) {
        var index = uuid == null ? this.get('hoverItemIndex') : uuid;

        this.set('selectedElementNameIndex', index);
        setTimeout(function () {
          var editableLabel = document.getElementById('element-name-editable-label-' + index);
          if (editableLabel) {
            editableLabel.click();
          }
        }, 0);
      },

      addCondition: function addCondition() {
        var newCondition = { uuid: crypto.randomUUID(), kind: '', data: {} };
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');

        steps[stepIdx].conditions.push(newCondition);
        this.send('dispatchAction', 'refreshSteps');
      },

      toggleNewElemDropdown: function toggleNewElemDropdown(onToggle) {
        onToggle();
      },

      onMouseUp: function onMouseUp(uuid, event) {
        var _this4 = this;

        this.set('clicks', this.get('clicks') + 1);
        if (this.get('clicks') === 1) {
          setTimeout(function () {
            var clicks = _this4.get('clicks');
            var selectedElementNameIndex = _this4.get('selectedElementNameIndex');
            _this4.set('clicks', 0);
            if (_this4.get("mouseY") === event.y && clicks === 1 && selectedElementNameIndex === -1) {
              _this4._changeSelectedElement(uuid);
            }
          }, 500);
        }
      },

      onMouseDown: function onMouseDown(event) {
        this.set("mouseY", event.y);
      },

      backToSteps: function backToSteps() {
        var stateToggleCallback = this.get('onStateChange');

        if (stateToggleCallback) {
          stateToggleCallback(null, -1, true);
        }
      },

      setConditionKind: function setConditionKind(index, kind) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var condition = steps[stepIdx].conditions[index];
        var values = condition.data[condition.kind];
        var newKind = this._computeConditionKind(kind, steps[stepIdx].conditions[index]);

        steps[stepIdx].conditions[index].kind = newKind;
        set(steps[stepIdx], 'conditions.' + index + '.data.' + condition.kind, undefined);

        set(steps[stepIdx], 'conditions.' + index + '.data.' + newKind, values);
        this.send('dispatchAction', 'refreshSteps');
      },

      setConditionSource: function setConditionSource(index, source) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var conditionsDataMapping = this.get('conditionsDataMapping');

        if (source.type === 'question') {
          var conditionStepIdx = source.step;
          var conditionElemIdx = source.element;
          var condition = steps[stepIdx].conditions[index];
          var values = condition.data[condition.kind];

          steps[stepIdx].conditions[index] = { kind: '', data: {} };
          steps[stepIdx].conditions[index].kind = 'answer_in';
          steps[stepIdx].conditions[index].data = copy(conditionsDataMapping['answer_in'], true);
          steps[stepIdx].conditions[index].data.answer_step = parseInt(conditionStepIdx);
          steps[stepIdx].conditions[index].data.answer_element = parseInt(conditionElemIdx);
          steps[stepIdx].conditions[index].data['answer_in'] = values;
        } else {
          var condition = steps[stepIdx].conditions[index];
          var values = condition.data[condition.kind] || [];
          var newKind = condition.kind.includes('answer') ? 'segment_value_in' : source.kind || 'segment_value_in';

          steps[stepIdx].conditions[index] = { kind: '', data: {} };
          steps[stepIdx].conditions[index].kind = newKind;
          steps[stepIdx].conditions[index].data = copy(conditionsDataMapping[newKind], true);
          steps[stepIdx].conditions[index].data.segment_name = source.text;
          steps[stepIdx].conditions[index].data[newKind] = values;
        }

        this.send('dispatchAction', 'refreshSteps');
      },

      toggleDropdown: function toggleDropdown(onToggle) {
        onToggle();
      },

      duplicateCondition: function duplicateCondition(index) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');
        var duplicateCondition = copy(steps[stepIdx].conditions[index], true);

        steps[stepIdx].conditions.splice(index + 1, 0, duplicateCondition);
        this.send('dispatchAction', 'refreshSteps');
      },

      deleteCondition: function deleteCondition(index) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');

        steps[stepIdx].conditions.splice(index, 1);
        this.send('dispatchAction', 'refreshSteps');
      },

      setFilteredSources: function setFilteredSources(value, parentKey) {
        this.set('conditionSourceFilterInput', value);
        this.set('conditionSourceFilterKey', parentKey);
      },

      addConditionValue: function addConditionValue(index, values) {
        var steps = this.get('steps');
        var stepIdx = this.get('selectedStepIndex');

        var condition = steps[stepIdx].conditions[index];
        set(steps[stepIdx], 'conditions.' + index + '.data.' + condition.kind, values.toArray());
        this.send('dispatchAction', 'refreshSteps');
      },

      editStepName: function editStepName(value) {
        var step = this.get('object');
        var steps = this.get('steps');
        var stepName = this.get('stepName');
        var stepIdx = this.get('selectedStepIndex');

        if (value !== -1) {
          this.set('stepName', value);
          this.set('object.name', value);
        } else if (value === -1 && stepName) {
          set(steps[stepIdx], 'name', step.name);
          this.send('dispatchAction', 'refreshSteps', steps);
          this.set('stepName', null);
        }
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/surveys-library/surveys/navbar/elements/elements-content}} alert component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */