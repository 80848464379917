define('due-dashboard/routes/companies/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    redirect: function redirect() {
      var _this = this;

      var promise = new RSVP.Promise(function (resolve) {
        return resolve(_this.get('currentAccount.content.account_custom_profile_right.sidebar'));
      });
      promise.then(function (sidebar) {
        if (sidebar && sidebar.get('elements').length > 0) {
          var element = _this.set('customFirstElement', sidebar.get('elements').find(function (element) {
            return element.route;
          }));
          if (element) {
            return element.param ? _this.transitionTo(element.route, element.param) : _this.transitionTo(element.route);
          }
        }
        _this.transitionTo('companies.overview');
      });
    }
  });
});