define('due-dashboard/components/digests/parts/kpi-list-item-part', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    kpiColumnTitle: computed('currentTag', {
      get: function get() {
        return this.get('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('compId') + '.kpi_list.' + this.get('index') + '.title');
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('compId') + '.kpi_list.' + this.get('index') + '.title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    analysingYear: computed('period', function () {
      return this.get('period') && this.get('period').includes('year');
    }),

    kpiColumnYearToDateTitle: computed('currentTag', {
      get: function get() {
        var base_path = 'translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('compId') + '.kpi_list.' + this.get('index');
        var title = this.get(base_path + '.title');
        var year_to_date_title = this.get(base_path + '.year_to_date_title');

        if (year_to_date_title === "") {
          return title === "" ? title : title + ' - YTD';
        }
        return year_to_date_title;
      },
      set: function set(_, value) {
        this.set('translations.' + this.get('currentTag').toLowerCase() + '.components.' + this.get('compId') + '.kpi_list.' + this.get('index') + '.year_to_date_title', value);
        this.get('saveDigestDelay')();
        return value;
      }
    }),

    actions: {

      removeKpiItem: function removeKpiItem() {
        var _this = this;

        var translations = this.get('translations');
        var comp_id = this.get('compId');
        Object.keys(translations).forEach(function (tag) {
          var to_remove = translations[tag].components[comp_id].kpi_list[_this.get('index')];
          translations[tag].components[comp_id].kpi_list.removeObject(to_remove);
        });
        this.get('removeKpiItem')(this.get('kpiItem'));
      }
    }
  });
});