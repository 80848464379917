define('due-dashboard/components/due-table/navigation-button', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    currentAccount: service('current-account'),
    _routing: service('-routing'),
    store: service('store'),
    filters: service('filters'),

    tagName: 'div',
    classNames: ['navigation-button'],
    classNameBindings: ['visibility'],

    translations: computed('config.translations', 'currentAccount.content.language.tag', function () {
      var translations = this.get('config.translations'),
          locale = this.get('currentAccount.content.language.tag').toUpperCase();

      return translations[locale];
    }),

    text: computed('translations', function () {
      var translations = this.get('translations');

      return translations ? translations.button_text : '';
    }),

    _setFilters: function _setFilters(filters) {
      var _this = this;

      var presentFilters = Object.entries(filters).filter(function (f) {
        return !!f;
      }),
          setFilters = [];
      var promise = _ember['default'].RSVP.resolve();

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = presentFilters[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _step$value = _slicedToArray(_step.value, 2);

          var filter = _step$value[0];
          var filterValue = _step$value[1];

          if (filter === 'withTags') {
            var tagIds = filterValue,
                tagPromises = tagIds.map(function (id) {
              return _this.store.findRecord('tag', id);
            });

            setFilters.push('tags');
            promise = _ember['default'].RSVP.all(tagPromises);
            promise.then(function (added_tags) {
              var added_tag_sentiments = _.reduce(added_tags, function (sentiments, tag) {
                var id = tag.get('id');

                sentiments[id] = ['allfeedback'];
                return sentiments;
              }, {});

              _this.set('filters.tags', added_tags);
              _this.set('filters.tags_sentiments', added_tag_sentiments);
            });
            this.get('filters').set('tagged', 'tagged');
          } else {
            setFilters.push(filter);
            this.get('filters').set(filter, filterValue);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('filters').set('hasFilter', true);
      promise.then(function (_) {
        _this.get('filters').set('additionalFilters', setFilters);
      });
      return promise;
    },

    actions: {
      transitionToTarget: function transitionToTarget() {
        var target = this.get('config.target'),
            filters = _extends({}, target.filters.template),
            routing = this.get('_routing');

        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = target.filters.substitutions[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var _step2$value = _slicedToArray(_step2.value, 2);

            var target_path = _step2$value[0];
            var source_path = _step2$value[1];

            var source_value = this.get(source_path);

            _.set(filters, target_path, source_value);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2['return']) {
              _iterator2['return']();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        this._setFilters(filters).then(function (_) {
          routing.transitionTo(target.route_name, target.dynamic_segments);
        });
      }
    }
  });
});