define('due-dashboard/components/survey-dashboard-filter', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({

    allSurveys: [],
    remainingSurveys: computed.setDiff('allSurveys', 'surveyFilter'),
    filteredSurveys: [],
    remainingFilteredSurveys: computed.setDiff('filteredSurveys', 'surveyFilter'),

    inputValue: '',

    init: function init() {
      var _this = this;

      if (typeof this.get('filter.value') === 'string') {
        this.set('filter.value', this.get('filter.value').split(','));
      }
      if (!this.get('filter.value')) {
        this.set('filter.value', this.get('filters.surveys').mapBy('id'));
      }
      var promise = this.store.query('survey', { filter: { status: 'live' }, page: { number: 1, size: 60 } });
      this.set('promise', promise);
      promise.then(function (surveys) {
        _this.set('allSurveys', surveys);
        _this._querySavedSurveys(surveys);
      });
      this._super.apply(this, arguments);
    },

    availableSurveys: computed('inputValue', 'remainingSurveys', 'remainingFilteredSurveys', function () {
      return this.get('inputValue.length') === 0 ? this.get('remainingSurveys') : this.get('remainingFilteredSurveys');
    }),

    surveyFilter: computed('filter.value', 'allSurveys', function () {
      var surveys = this.get('filter.value');
      this.set('filters.surveys', this.get('allSurveys').toArray().filter(function (a) {
        return surveys.includes(a.id);
      }));
      return this.get('allSurveys').toArray().filter(function (a) {
        return surveys.includes(a.id);
      });
    }),

    surveys_legend: computed('i18n.locale', 'current_company', function () {
      return this.get('current_company.custom_surveys_legend') || this.get('i18n').t('sidebar.filters.surveys.title');
    }),

    dueInputProps: computed('i18n.locale', 'current_company', function () {
      var i18n = this.get('i18n');
      return {
        'class': 'filter-label',
        label: this.get('current_company.custom_surveys_legend') || this.get('i18n').t('sidebar.filters.surveys.title'),
        placeholder: i18n.t('global.choose_surveys')
      };
    }),

    /**
     * Search surveys in the list from its name
     * @param {String} term Survey name to search
     */
    _searchSurvey: function _searchSurvey(term) {
      var _this2 = this;

      this.set('inputValue', term);
      var promise = this.store.query('survey', { filter: { status: 'live', name: term }, page: { number: 1, size: 20 } });
      this.set('promise', promise);
      promise.then(function (surveys) {
        return _this2.set('filteredSurveys', surveys);
      });
    },

    /**
     * Add missing surveys from saved filter
     * @param {Array<Model<Survey>>} Surveys to add if they are missing
     */
    _querySavedSurveys: function _querySavedSurveys(surveys) {
      var _this3 = this;

      var missingSurveys = this.get('filter.value').filter(function (id) {
        return surveys.find(function (s) {
          return s.get('id') === id;
        }) === undefined;
      });

      if (missingSurveys.length > 0) {
        this.store.query('survey', { filter: { status: 'live', id: missingSurveys } }).then(function (surveys) {
          _this3.set('allSurveys', _this3.get('allSurveys').toArray().concat(surveys.toArray()));
        });
      }
    },

    actions: {
      /**
       * Delete the language fitler from dashboard filters
       */
      deleteFilter: function deleteFilter() {
        this.set('filters.surveys', []);
        this.get('deleteFilter')(this.get('filter'));
      },

      /**
       * Search surveys in the list from its name
       * @param {String} term Survey name to search
       */
      searchSurveys: function searchSurveys(term) {
        run.debounce(this, this._searchSurvey, term, 600);
      },

      /**
       * Add the selected survey to the filter list
       * @param {Model<Suvey>} survey Survey selected from dropdown
       */
      onSelectSurveyFilter: function onSelectSurveyFilter(survey) {
        var allSurveys = this.get('allSurveys').toArray();
        this.set('inputValue', '');
        if (allSurveys.find(function (s) {
          return s.get('id') === survey.get('id');
        }) === undefined) {
          allSurveys.push(survey);
          this.set('allSurveys', allSurveys);
        }
        var surveys = this.get('filters.surveys');
        surveys.push(survey);
        this.set('filter.value', surveys.mapBy('id'));
        this.set('filters.surveys', surveys);
        this.get('modifiedSearch')();
      },

      /**
       * Delete a survey from the filters
       * @param {Model<Survey>} survey Survey to delete
       */
      onDeleteSurveyFilter: function onDeleteSurveyFilter(survey) {
        this.set('filter.value', this.get('filter.value').filter(function (v) {
          return v !== survey.get('id');
        }));
        this.set('filters.surveys', this.get('filters.surveys').filter(function (s) {
          return s.get('id') !== survey.get('id');
        }));
        this.get('modifiedSearch')();
      }
    }

  });
});