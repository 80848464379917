define('due-dashboard/routes/companies', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    filters: service(),

    model: function model(params) {
      var _this = this;

      if (params.filterStartDate && params.filterEndDate) {
        this.get('filters').set('startDate', params.filterStartDate);
        this.get('filters').set('endDate', params.filterEndDate);
      }

      if (params.filters) {
        (function () {
          var filters = JSON.parse(params.filters);

          _this.get('filters').set('fromUrl', true);
          Object.keys(filters).map(function (filter) {
            if (filters[filter]) {
              _this.get('filters').set(filter, filters[filter]);
            }
            _this.get('filters').set('hasFilter', true);
          });
        })();
      }
      // to remove params from url for first opened page
      if (window.location.href.includes('?')) {
        window.history.pushState({}, '', window.location.href.split('?')[0]);
      }
      return _ember['default'].RSVP.hash({
        displayFilters: false
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('filters', undefined); // to remove params from url for all pages
    }

  });

  // filters to be JSON.stringify + url encoded
  var toto = {
    'filterStartDate': '2022-01-01',
    'filterEndDate': '2022-12-01',
    'comment': 'with',
    'resolved': 'resolved',
    'profiles': [{ 'name': 'promoter' }],
    'surveys': [{ 'id': '38e89fd2-05ad-40f1-b5de-4b2d21cac4bd' }],
    'tagged': 'tagged',
    'tags': [{ 'id': '868584a6-ae62-420f-83fb-8420fc86b376' }, { 'id': 'd8123fdf-72c6-433b-b406-946e7e8c1f28' }, { 'id': 'be6c5386-9158-4a56-a63b-2ae28f55cc48' }],
    'excluded_tags': [{ 'id': '868584a6-ae62-420f-83fb-8420fc86b376' }, { 'id': 'd8123fdf-72c6-433b-b406-946e7e8c1f28' }],
    'deleted': 'deleted',
    'segments': {
      'd3b40972-dd7c-47b9-b512-8110cb3b858a': 'Chrome',
      '001da32a-b277-4f29-95df-4c209d6a7954': 'value1,value2',
      'c94316da-fbe5-401f-b3f2-614bd7fbfbff': 'desktop'
    },
    'languages': [{ 'id': 'd3e73c5c-7ac3-4468-9b52-adcd6c29a6e2' }, { 'id': 'cf6accb7-1fa4-4abb-8486-043a01996fd9' }],
    'sentiments': [{ 'value': 2 }, { 'value': 1 }],
    'tags_sentiments': {
      '868584a6-ae62-420f-83fb-8420fc86b376': [],
      'd8123fdf-72c6-433b-b406-946e7e8c1f28': [],
      'be6c5386-9158-4a56-a63b-2ae28f55cc48': ['positive']
    }
  };
});